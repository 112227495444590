<template>
  <div class="container text-center left-10" id="container">
    <h1 class="head-title">Leader board</h1>
    <button
      class="bg-transparent position-relative border-0 ml-2 mb-3"
      @click="showWModal = true"
    >
      <div class="popup_button-img"></div>
      <div class="question-mark">
        <img src="images/question-mark.png" alt="" class="question-mark_img" />
      </div>
    </button>
    <input
      v-model="searchTerm"
      @keyup="search($event)"
      @paste="search($event)"
      type="text"
      name="search"
      class="search-field form-control"
      placeholder="search user"
    />
    <div class="daily-winner_wrapper">
      <div class="container">
        <div class="leaderBoard-body scroll">
          <div
            class="leaderBoard-content"
            v-for="(item, index) in info"
            :key="index"
          >
            <div class="leaderBoard-reward" v-if="index == 0">
              <img src="images/gold_medal.png" alt="" />
            </div>
            <div class="leaderBoard-reward" v-if="index == 1">
              <img src="images/Silver-Medal.png" alt="" />
            </div>
            <div class="leaderBoard-reward" v-if="index == 2">
              <img src="images/bronze-medal.png" alt="" />
            </div>
            <div class="leaderBoard-reward" v-if="index > 2">
              <h4>{{ index + 1 }}</h4>
            </div>
            <div class="leaderBoard-userImg">
              <img src="images/round-logo.png" alt="" />
            </div>
            <div class="leaderBoard-info">
              <h4 class="username">{{ item.account }}</h4>
              <h4 class="total-reward">{{ item.points }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LeaderBardInfoModal
      :error="error"
      v-if="showWModal"
      v-on:w-modal-hide-click="showWModal = false"
    />
  </div>
</template>

<script>
import LeaderBardInfoModal from "../components/LeaderBoardInfoModal.vue";
import axios from "axios";
export default {
  name: "LeaderBoard",
  components: {
    LeaderBardInfoModal,
  },
  data() {
    return {
      error: false,
      searchTerm: "",
      info: null,
      showWModal: false,
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_OFF_CHAIN}`).then((response) => {
      if (response.data) this.info = response.data.data;
      this.repo = this.info;
    });
  },
  methods: {
    search() {
      if(this.searchTerm){
        axios.get(`${process.env.VUE_APP_OFF_CHAIN_SEARCH}${this.searchTerm}`).then((response) => {
          if (response.data){
            this.info = response.data.data;
        } 
      });
      }else{
        this.info = this.repo;
      }
      // this.info = this.repo.filter((item) =>
      //   item.account.includes(this.searchTerm)
      // );
    },
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
  height: 349px;
}
.search-field {
  width: 508px;
  margin: 0 auto;
  background: #0c0b0a;
  border-color: #94633d;
  color: #e9c8a8;
  height: 34px;
  font-size: 16px;
}
.search-field:active,
.search-field:focus {
  background-color: #0f0905;
  color: #e9c8a8;
}
</style>