<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body" >
                <p v-if="error">{{error}}</p>
                <div>
                    <a href="JavaScript:void(0)" class="btn-cross remove" @click="hideModal($event)">&times;</a>
                    <h3 class="tm5 title-head">Confirm Your Selection</h3>
                </div>
                <br />
                <div class="confirm-card">
                <template v-if="selectedRunes.length">
                  <img
                    v-for="(card,i) in selectedRunes" :key="i" :row="card"
                    :src="showImg(card.data.img)"
                    class="train-img"
                    v-bind:style="{objectFit:'contain' }"
                  />
                </template>
                <template v-else>
                <img
                  :src="selectedImage"
                  class="train-img fit-300"
                  alt=""
                />
                </template>
                <template v-if="rune">
                  <img
                    :src="showImg(rune.data.img)"
                    class="train-img"
                  />
                </template>
                <h4 class="tm5 title-head">+{{token}}</h4>
                </div>
                  <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="transfer()">
                      <img src="images/login.png" alt="" class="btn_sm">
                      <div class="position-absolute btn_text">
                          <h3 class="text-center">Confirm</h3>
                      </div>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>
<script>
import ApiService from "../_services/Service";
import { mapGetters } from "vuex";
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
 name:"TrainingConfirm",
 props:["error",'showModel','rarity','filter'],
 computed: {
  ...mapGetters(["selectedRunes"]),
},
 data () {
    return {
      showModal: true,
      toAccount: "",
      rune: null,
      token: null,
      amount:0
    }
  },
  async mounted() {
    this.toAccount = localStorage.getItem("wax_user")
    if(this.rarity=="silver"){
      this.token = this.filter=="weapons" ? 800:400;
    }else if(this.rarity=="gold"){
      this.token = this.filter=="weapons" ? 2000:1000;
    }else if(this.rarity=="bronze"){
      this.filter=="weapons" ? 400:200;
    }
    await ApiService.getRune(this.rarity,(res)=>{
      if(res){
        this.rune = res;
      }else{
        toast.error("No rune found");
      }
    });
  },
  methods:{
    showImg(hash){
      return ApiService.getImgUrl(hash);
    },
    hideModal(e){
        this.$emit("modal-hide-click",e);
    },
    async transfer(){
        let cards = this.selectedRunes.map((item)=>item.asset_id);
        cards.push(this.rune.asset_id);
        if(cards.length>0 && this.rune && (this.filter=="characters" || this.filter=="weapons")){
          await ApiService.train({
              from: localStorage.getItem("wax_user"),
              to: 'midgardtrade',
              asset_ids: cards,
              memo: this.filter==="characters" ? "charupgrade":"weaponupgrade"
          },((res)=>{
            if(res.processed){
                this.$store.commit('decrementBalance', this.token)
                toast.success("Training has been completed.");
                ApiService.saveActivity({
                  trxId:res.processed.id,
                  blockNum:res.processed.block_num, 
                  type:"train", 
                  duration: 0,
                  account:localStorage.getItem("wax_user"), 
                  rarity:this.rarity
                }).then((apires)=>{
                  ApiService.leaderBoardEntry({
                    stake_id:0,
                    type:"train",
                    duration:0,
                    account:localStorage.getItem("wax_user"),
                    status:"Done",
                    log:apires,
                    rarity:this.rarity
                  });
              });
              this.$emit("modal-hide-click",res);
            }else{
              ApiService.leaderBoardEntry({
                stake_id:0,
                type:"train",
                duration:0,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:res,
                rarity:this.rarity
              });
            }
          }),((error)=>{  
            error = ApiService.errorFormat(error)
            toast.error(error);
            ApiService.leaderBoardEntry({
                stake_id:0,
                type:"train",
                duration:0,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:[],
                rarity:this.rarity
              });
          }));
        }else{
            let error = "Please provide input data";
            toast.error(error);
        }
    }
  }
}
</script>

<style scoped>
/* .modal-dialog{
  width: 300px !important;
  max-width: 550px;
} */
.search{
  margin-bottom: 4px;
}
.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.train-img{
  width: 100px;
}
.modal-body{
    overflow-y: auto;
}
.modal-mask{
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 20px;
    left: 0;
    right: 0;
    text-align: center;
}
.modal-body{
  background-image: url(../../public/images/modal.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 637px;
    height: 600px;
}
.modal-content{
  background: #000000b5;
  widows: 540px;
}
.modal-body .btn-cross{
    position: absolute;
    top: 15px;
    right: 0;
    left: 91%;
    font-size: 24px;
    color: #776656;
}
.btn-cross:hover{
  color:#fff
}
.btn_sm{
  width: 200px;
}
.title-head{
  margin-top: 10%;
}
</style>