<template>
  <header class="p-0 m-0 login-header"></header>
  <main class="d-flex">
      <div class="midgard-logo mt-2 mx-auto w-50">
         <img src="images/Midgard_Text_Part_V2 TMSİZ.png" class="w-50 mx-auto" alt="">
      </div>

      <div class="logInSection">
        <div class="login_btn">
            <a href="JavaScript:void(0)" @click.prevent="handle_login">
                <img src="images/login.png" alt="" class="loginImg custom-btn">
                <div class="loginText">
                    <h3 class="btn-text">Log in</h3>
                </div>
             </a>
        </div>

       <div class="howTo_play-btn">
        <a href="/guide" class=" bg-transparent" id="howTo_play" @click.prevent="toGuide()">
            <img src="images/how_to_play.png" class="custom-btn-sm" alt="">
            <div class="loginText-2">
             <h3 class="btn-text">How to play</h3>
         </div>
         </a>
       </div>

      </div>
      
      <div class="popup" :class="{'show-popup': showPopup}">
         <div class="popup-wrapper">
            <div class="position-relative">
                <a href="/guide" class="close_popup-btn remove">&times;</a>
                <div class="popup-heading">
                    <h4>How To Play</h4>
                </div>
            </div>
           <div class="popup_content">
              <div class="popup_img">
                  <img src="images/modal.png" alt="" class="">
              </div>
              <div class="body-content">
                <p>The goal of this game is to battle within dungeons to earn
Yggdrasil tokens. On launching the game, it would open up to a
"login" option which would be the start point for the journey to
Midgard. </p>
<p>After logging in, players would have to choose the
wallet that they would like to play with. For this game, <strong>Wax cloud</strong> or <strong>Anchor</strong> would be utilized.
</p> <p>Players would have to select three weapons from their
weapon chest that would be used throughout the duration of a
game session.</p> <p>Players would then get to choose the character
that they would like to take to the dungeon.
The character and weapon attributes would determine the
<strong>"number of attacks"</strong> that a player would get for each game
session.</p> The option to choose the duration <strong>(6 hours, 12 hours,
and 24 hours)</strong> of the game would pop up next. The next screen
would show a timer that starts counting down the selected
duration.
<p>After the end of the selected duration, players would have the
option of selecting the earned Yggdrasil token, and NFT drops
at the end of each 24 hours by clicking on "claim" at the top right
corner of the screen.
</p>
<p>
  Players could withdraw their Yggdrasil Tokens to their wallets,
when they reach the <strong>3000 Yggdrasill</strong> in the game.
If users keep <strong>5000 Yggdrasill</strong> in their accounts and don't
withdraw, they will be rewarded with <strong>%10 Reduce Waiting
Time in the game.</strong>
</p>
<p>
If users keep <strong>10000 Yggdrasill</strong> in their accounts and don't
withdraw, they will be rewarded with <strong>%20 Reduce Waiting
Time in the game.</strong>
</p>
<p>
Players can repeat the entire gameplay after the end of a
duration.
</p>
        <a href="/guide" class="btn-more">Details Guide</a>
              </div>
           </div>
         </div>
      </div>

      <div class="iconSection text-white">
      <a class="text-white" href="https://twitter.com/midgardnft" target="_blank"><img src="images/twitter.png" alt=""></a>
      <a class="text-white" href="https://t.me/midgardnftchat" target="_blank"><img src="images/telegram.png" alt=""></a>
      <a class="text-white" href="https://discord.gg/frSfYVEBbP" target="_blank"><img src="images/discord.png" alt=""></a>
      </div>
  </main>
  <div id="ual-div"></div>
</template>

<script lang="js">
import LoginService from '../_services/login_service';
export default {
    name: 'login',
    data(){
        return {
            "ual":null,
             showPopup:false
        }
    },
    inheritAttrs: false,
    mounted(){
        localStorage.removeItem("ual-session-authenticator")
        localStorage.removeItem("ual-session-expiration")
        localStorage.removeItem("autoLogin")
        localStorage.removeItem("loglevel:webpack-dev-server")
        localStorage.removeItem("ual-wax:autologin")
        localStorage.removeItem("wax_user")
        localStorage.clear()
    },
     methods: {
        handle_login:function () {
            if(this.ual){
                this.ual.init()
                var [buttonUAL] = document.body.getElementsByClassName('ual-button-gen');
                buttonUAL.click();
            }else{
                localStorage.clear();
                const loginService = new LoginService();
                this.ual = loginService.handle_login();
            }
        },
        toGuide:function () {
            this.$router.push("/guide");
        }
    }
}
</script>
<style>
 .fa-chevron-right{
  padding: 15px !important;
 }
 strong{
   color: rgb(41, 96, 247);
 }
 .ual-modal-close {
    font-size: 4em !important;
}
.ual-modal-content-description {
    color: rgb(251 253 255) !important;
}
div.ual-authenticators-list  > *:first-child{
  background:#62361e !important;
}
.btn-more {
    background-image: url(/img/btn_cliam.8e3816af.png) !important;
    background-repeat: round;
    padding: 9px;
    font-family: 'myFirstFont';
    color: #110b07;
    font-size: 17px;
}
</style>