<template>
  <div class="raiding-wrapper">
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-sm-8">
        <div class="raiding-img-prev d-flex">
          <template v-if="selectedChar[0]">
            <div class="card">
              <img :src="getImgUrl(selectedChar[0].data.img)" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="card" @click="ShowCardSearchModal = true">
              <img src="images/frame-2.png" alt="" />
            </div>
          </template>
          <template v-if="selectedChar[1]">
            <div class="card">
              <img :src="getImgUrl(selectedChar[1].data.img)" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="card" @click="ShowCardSearchModal = true">
              <img src="images/frame-2.png" alt="" />
            </div>
          </template>
          <template v-if="selectedChar[2]">
            <div class="card">
              <img :src="getImgUrl(selectedChar[2].data.img)" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="card" @click="ShowCardSearchModal = true">
              <img src="images/frame-2.png" alt="" />
            </div>
          </template>
          <template v-if="selectedChar[3]">
            <div class="card">
              <img :src="getImgUrl(selectedChar[3].data.img)" alt="" />
            </div>
          </template>
          <template v-else>
            <div class="card" @click="ShowCardSearchModal = true">
              <img src="images/frame-2.png" alt="" />
            </div>
          </template>
        </div>
      </div>
      <div class="col-md-2 col-sm-2">
        <div class="raiding-total_prize d-flex">
          <h2 class="buy-btn item-price_text text-white mr-2">10,000</h2>
          <img src="/img/coin-icon.6abb074e.png" class="mt-2" alt="" />
        </div>
        <!-- <select
          name="duration"
          class="form-control"
          @change="duratoinSelect($event)"
        >
          <option value="7">one Week</option>
          <option value="30">one Month</option>
        </select> -->
        <div class="btn-container d-inline">
          <button class="large-btn mb-3">
            <div class="btn-container__img">
              <div class="dropdown-area">
                <div class="position-relative">
                  <img src="images/field.png" alt="" />
                  <div
                    class="dropdown position-absolute d-flex"
                    @click.prevent="activeMenu = !activeMenu"
                  >
                    <h3>{{ rarity }}</h3>
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <!-- Dropdown menu -->
                  <div
                    class="dropdown-menu"
                    :class="{ 'active-menu': activeMenu }"
                  >
                    <ul class="nav">
                      <a href="#" @click="duratoinSelect(7)"
                        >One week
                        <img src="images/choose-icon.png" class="dp_img"
                      /></a>
                      <a href="#" @click="duratoinSelect(30)"
                        >One month
                        <img src="images/choose-icon.png" class="dp_img" />
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="btn-container">
          <button class="med-btn" @click="raid()">
            <div class="btn-container__img">
              <img src="images/md-btn.png" alt="" />
              <div class="btn-container__text">
                <h4 class="ml-3">Raid</h4>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <Runes
    v-if="ShowCardSearchModal"
    :cards="myAssets"
    v-on:modal-hide-click="ShowCardSearchModal = false"
  />
</template>
<script>
import { mapState } from "vuex";
import Runes from "../components/Runes";
import ApiService from "../_services/Service";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "Raiding",
  components: {
    Runes,
  },
  data: () => {
    return {
      ShowCardSearchModal: false,
      duration: 7,
      activeMenu: false,
      rarity: "One Week",
    };
  },
  computed: mapState(["myAssets", "selectedChar"]),
  methods: {
    getImgUrl(pet) {
      return ApiService.getImgUrl(pet);
    },
    duratoinSelect(val) {
      this.duration = val;
      this.activeMenu = false;
      this.rarity = this.duration===7? "One Week":"One Month";
    },
    async raid() {
      let cards = this.selectedChar.map((item) => item.asset_id);
      if (cards.length > 0) {
        await ApiService.train(
          {
            from: localStorage.getItem("wax_user"),
            to: "midgardtrade",
            asset_ids: cards,
            memo: `staking:${this.duration}`,
          },
          (res) => {
            if (res.processed) {
              this.$store.commit("decrementBalance", 10000);
              toast.success("Transaction processing.");
              this.$emit("modal-hide-click", res);
              this.$store.dispatch("getMyStakes");
            }
          },
          (error) => {
            error = ApiService.errorFormat(error);
            toast.error(error);
          }
        );
      } else {
        let error = "Please provide input data";
        toast.error(error);
      }
    },
  },
};
</script>

