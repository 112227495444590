import { UALJs } from "ual-plainjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
import router from '../router'
import ApiService from './Service'

// login functio
export default class LoginService {
    handle_login() {
        const myChain = {
            // chain id
            // chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
            chainId: process.env.VUE_APP_CHAIN_ID,
            // end point details if available
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };
        // this call after successfull login from EOSIO
        const myLoginCallback = users => {
            this.users = users
            console.log(ual);
            if (this.users[0].session) {
                localStorage.setItem("ual-session-authenticator", "anchor")
            } else {
                localStorage.setItem("ual-session-authenticator", "Wax")
            }
            // session details save in local storage
            localStorage.setItem('wax_user', this.users[0].accountName)
                // navigate to dashboard after login

            ApiService.logLogin(this.users[0].accountName);
            router.push('/dashboard')
        }
        const myAppName = 'midgardclash'

        //auth partners
        const wax = new Wax([myChain], { appName: myAppName });
        const anchor = new Anchor([myChain], { appName: myAppName });

        // login component load to HTML
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }

        // validate authentication
        var ual = new UALJs(myLoginCallback, [myChain], myAppName, [wax, anchor], myAppRoot);
        (async() => {
            await ual.init();
        })()

        // hide UAL button load from liabary after click
        const [buttonUAL] = document.body.getElementsByClassName('ual-button-gen');
        buttonUAL.click();
        var popups = document.getElementsByClassName('ual-button-gen'),
            i = popups.length;
        if (i) {
            try {
                do {
                    i--;
                    if (popups)
                        popups[i].remove();
                } while (i >= 0)
            } catch (error) {
                console.warn(error)
            }
        }
        return ual
    }
    async loginSession() {
        const myChain = {
            chainId: process.env.VUE_APP_CHAIN_ID,
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };

        // this call after successfull login from EOSIO
        const myLoginCallback = users => {
            console.log(ual);
            this.users = users
            if (this.users[0].session) {
                localStorage.setItem("ual-session-authenticator", "anchor")
            } else {
                localStorage.setItem("ual-session-authenticator", "Wax")
            }
            // session details save in local storage
            localStorage.setItem('wax_user', this.users[0].accountName)
        }

        const myAppName = 'midgardclash'

        //auth partners
        const wax = new Wax([myChain], { appName: myAppName });
        const anchor = new Anchor([myChain], { appName: myAppName });

        // login component load to HTML
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }

        // validate authentication
        const ual = new UALJs(myLoginCallback, [myChain], myAppName, [wax, anchor], myAppRoot);
        await ual.init();
        return ual
    }

}