<template>
  <div class="main">
    <div class="position-absolute btn-pos">
      <div class="btn-container w-75">
        <a href="/dashboard" class="small-btn">
          <div class="btn-container__img">
            <img src="../../public/images/backBtn.png" alt="" />

            <div class="btn-container__text">
              <h4 class="ml-3">Back</h4>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="container" id="mt-20">
      <h1 class="mb-3 d-inline-block head-title">Training</h1>

      <div class="row align-items-center justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card bg-transparent large-card">
            <a href="/RunesConversion" class="position-relative">
              <div class="img-wrapper">
                <img src="../../public/images/train-card.png" alt="" />
                <div class="training-box">
                  <div class="training-box__text">
                    <h4 class="text-center">Rune Conversion</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card bg-transparent large-card">
            <a href="/blending" class="position-relative">
              <div class="img-wrapper">
                <img src="../../public/images/bleding.png" alt="" />
                <div class="training-box">
                  <div class="training-box__text">
                    <h4 class="text-center">Rune Crafting</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card bg-transparent large-card">
            <a href="/train" class="position-relative">
              <div class="img-wrapper">
                <img src="../../public/images/train-card.png" alt="" />
                <div class="training-box">
                  <div class="training-box__text">
                    <h4 class="text-center">Train Your Card</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import router from "../router";
export default {
  name: "Training",
  data() {
    return {};
  },
  mounted() {
    if (!localStorage.getItem("wax_user")) {
      router.push("/login");
    }
  },
  methods: {
    cardClicked() {
      // this.selectedImage = "images/ " + img;
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
#container {
  margin-top: 10% !important;
}
.large-card {
  width: 100%;
  margin-right: 10px;
}
.img-wrapper {
  width: 100%;
  height: 100%;
}
.large-card .img-wrapper img {
  width: 100%;
}

.large-card .img-wrapper:hover img,
.large-card .img-wrapper:hover h4 {
  transform: translateY(-10px);
  box-shadow: 0px 15px 10px -15px #111;
}

.btn_xlg {
  width: 70%;
}
.tx-center {
  position: absolute;
  display: inline-block;
  top: -6px;
  left: 27%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

