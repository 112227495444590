<template>
  <div class="container" id="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-12 col-md-2 col-lg-6 col-xl-3">
        <div class="card bg-transparent large-card">
          <a href="/attack" class="position-relative">
            <div class="img-wrapper">
              <img src="../../public/images/attack.png" alt="" />
              <div class="training-box">
                <div class="training-box__text">
                  <h1 class="text-center">Attack</h1>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-6 col-xl-3">
        <div class="card bg-transparent large-card">
          <a href="/market" class="position-relative">
            <div class="img-wrapper">
              <img src="../../public/images/inventory.png" alt="" />
              <div class="training-box">
                <div class="training-box__text">
                  <h1 class="text-center">Market</h1>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-6 col-xl-3">
        <div class="card bg-transparent large-card">
          <a href="/training" class="position-relative">
            <div class="img-wrapper">
              <img src="images/trainin.png" alt="" />
              <div class="training-box">
                <div class="training-box__text">
                  <h1 class="text-center">Training</h1>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-6 col-xl-3">
        <div class="card bg-transparent large-card">
          <a href="/raiding" class="position-relative">
            <div class="img-wrapper">
              <img src="images/raiding.png" alt="" />
              <div class="training-box">
                <div class="training-box__text">
                  <h1 class="text-center">Raiding</h1>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'Dashboard',
  data(){
    return {
      loginService: null
    }
  },
  created() {
  },
  methods: {
        login(){
        },
    }
}
</script>
<style scoped>
#container {
  margin-top: 17% !important;
}

.card {
  /* margin-top: 40px !important; */
  cursor: pointer;
  margin-bottom: 20px;
}
</style>