<template>
  <transition name="modal fade">
    <div class="modal-mask modal-backdrop">
      <div class="">
        <div class="modal-wrapper">
          <div class="modal-dialogs" role="document">
            <div class="modal-content modal-img">
              <div class="modal-body p-5 overflow-hidden">
                <p v-if="error">{{ error }}</p>
                <div class="text-center">
                  <a
                    href="JavaScript:void(0)"
                    class="btn-cross text-right close-modal"
                    @click="hideModal($event)"
                    >&times;</a
                  >
                  <h1 class="tm5 title-head d-inline-block">Scoring</h1>
                  <div class="scroll overflow-y">
                    <div class="modal-text text-left mb-5">
                      <h1 class="title">Raid</h1>
                      <h2 class="title-1">1 Week:</h2>
                      <p>
                        Users will get <b>1 point</b> from each bronze
                        character sent for raid, when they claim it after it
                        finishes.
                      </p>
                      <p>
                        Users will get <b>2 points</b> from each silver
                        character sent for raid, when they claim it after it
                        finishes.
                      </p>
                      <p>
                        Users will get <b>4 points</b> from each gold character
                        sent for raid, when they claim it after it finishes.
                      </p>
                      <p>
                        Users will get <b>8 points</b> from each platinum and
                        ownership character sent for raid, when they claim it
                        after it finishes.
                      </p>
                    </div>
                    <div class="modal-text text-left mb-5">
                      <h2>1 month:</h2>
                      <p>
                        Users will get <b>5 points</b> from each bronze character
                        sent for raid, when they claim it after it finishes.
                      </p>
                      <p>
                        Users will get <b>10 points</b> from each silver
                        character sent for raid, when they claim it after it
                        finishes.
                      </p>
                      <p>
                        Users will get <b>20 points</b> from each gold character
                        sent for raid, when they claim it after it finishes.
                      </p>
                      <p>
                        Users will get <b>40 points</b> from each platinum and
                        ownership character sent for raid, when they claim it
                        after it finishes.
                      </p>
                    </div>
                    <div class="modal-text text-left mb-5">
                      <h1 class="title">Training:</h1>
                      <p>
                        Users will get <b>1 point</b> from each (bronze card to
                        silver card) training.
                      </p>
                      <p>
                        Users will get <b>3 points</b> from each (silver card to
                        gold card) training.
                      </p>
                      <p>
                        Users will get <b>8 points</b> from each (gold card to
                        platinum card) training.
                      </p>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  props: ["error", "showModel"],
  showModal: true,
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-wrapper {
  overflow: hidden !important;
}
.close-modal {
  font-size: 28px;
  position: absolute;
  top: 28px;
  right: 31px;
  color: #e9c8a8;
}
.modal-dialogs {
  width: 485px;
  margin: auto;
  /* max-width: 700px; */
}
.modal-text p {
  font-size: 1.46rem;
  letter-spacing: 1px;
  color: #e9c8a8;
}
.modal-text p b {
  color: red;
  font-size: 2rem;
  font-weight: bolder;
}
.title {
  font-size: 3rem;
}
.title-1 {
  font-size: 2.33rem;
}
.modal-img {
  background: url("../../public/images/modal-frame-min.png") no-repeat;
  background-size: contain;
  height: 408px;
}
.coin-img img {
  width: 20px !important;
  height: 20px !important;
}
.infotext {
  margin-left: 26px;
  font-size: 27px;
  /* margin-top: 30px; */
  position: relative;
  top: 3px;
}
.character {
  position: relative;
}
.character-train_card img {
  width: 100%;
  height: 66px;
  /* padding: 3px; */
}
.character-train__img {
  padding: 3px;
}
.sliver-rune .character-train__img {
  padding: 0 !important;
}
.char-pos {
  position: absolute;
  top: 37px;
  padding: 0 !important;
  left: 6px;
}
.sliver-rune .char-pos {
  left: 27px;
}
.char-pos img {
  height: 57px;
}
</style>
