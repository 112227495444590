<template>
  <div class="container text-center position-fixed left-10" id="container">
    <h1 class="head-title">Daily Winners</h1>
    <div class="daily-winner_wrapper">
      <div class="container">
        <div class="daily-winner_body scroll">
            <template v-if="dailyWinners && dailyWinners !== 'undefined'">
              <div class="row mb-3 align-items-center"
                v-for="(item, index) in dailyWinners"
                :key="index"
              >
                <div class="col-md-6">
                  <div class="d-flex align-items-center">
                    <div class="sr-no">
                      <h4>{{index+1}}</h4>
                    </div>
                    <div class="user-char px-4">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="user-title">
                      <h3>{{item.account}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <img
                      :src="item.template"
                      alt=""
                      class="daily-winner__card"
                      @error="imageLoadError(item)"
                    />
                  </div>
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ApiService from "../_services/Service";
export default {
  name: "DailyWinners",
  computed: {
    ...mapState([
      "dailyWinners"
    ]),
  },
  async mounted() {
    await this.dailyReward();
  },
  methods: {
    imageLoadError (item) {
      this.$store.dispatch("getTempateImage",item);
    },
    getAvatar(){
      // ApiService.userPhoto(username,(res)=>{
      //   if(res.rows.length){
      //     return ApiService.getImgUrl(res.rows[0].photo_hash);
      //   }
        return "images/round-logo.png";
      // });
    },
    async dailyReward(lowerBound=null){
        await ApiService.dailyWinners(lowerBound,async(res) => {
        if (res.rows.length) {
              this.$store.commit('addDailyWinners', res.rows);
            }
        });
    },
  },
};
</script>
<style>
.daily-winner_wrapper {
  background: url("/images/raiding-card-frame.png") no-repeat;
  background-size: contain;
  height: 430px;
  margin: auto;
  width: 525px;
}
.left-10 {
  top: 0;
  left: 7%;
}
.daily-winner_body {
  padding: 30px;
  overflow: scroll;
  overflow-x: hidden;
  height: 380px;
}
.user-char img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.daily-winner_wrapper .daily-winner_body .card {
  width: 34px;
  height: 50px;
  margin-left: auto;
}
.user-title h3,
.sr-no h4 {
  color: #e9c8a8;
}
.daily-winner_wrapper .daily-winner_body .card .daily-winner__card {
  height: 100%;
}
</style>
<style scoped>
.container{
 padding: 20px;
}
</style>