<template>
  <div class="container mt-10">
    <div class="row">
      <div class="col-sm-12 hero-logo">
        <div
      class="
        popup-wrapper
        d-flex
        justify-content-center
        align-items-center
        mb-4
      "
    >
          <h1 class="head-title">Rune Pieces</h1>
        </div>
        <div
      class="
        popup-wrapper
        d-flex
        justify-content-center
        align-items-center
        mb-4
      "
    >
          <button @click="burnAll(runes)" class="btn btn-success">Convert 10</button>
        </div>
      </div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    />
    <div class="row align-items-center justify-content-center no-gutters">
      <div
        class="col-lg-3 col-md-4 col-sm-6"
        v-for="(item, index) in runes"
        :key="index"
      >
        <div class="inventory-box">
          <div class="card">
            <input
              type="checkbox"
              :id="'card' + index"
              class="more"
              aria-hidden="true"
            />
            <div class="content">
              <div
                class="front"
                :style="{
                  backgroundImage: 'url(' + getImgUrl(item.data.img) + ')',
                }"
              >
                <div class="inner" @click.prevent="burn(item.asset_id)">
                  <label
                    :for="'card' + index"
                    class="button"
                    aria-hidden="true"
                  >
                    Convert
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script lang="js">
import ApiService from "../_services/Service";
import Loading from 'vue-loading-overlay';
import {mapGetters} from 'vuex';
export default {
    name:"RunesConversion",
    components:{
    Loading
  },
  computed: {
    ...mapGetters(["runes"]),
  },
  data () {
    return {
      currentRoute: null,
      userFilter: null,
      authenticator: null,
      userCollection: process.env.VUE_APP_COLLECTION,
      showReadyButton: false,
      info: null,
      apiRes: null,
      collections: null,
      isLoading: false,
      fullPage: true,
      status: false,
      assetId:"",
      row:{},
      assetIds:[],
      showModal: false,
      showStackModal: false,
      error:false,
      activeMenuSearch:false,
      activeMenu:false
    }
  },
    mounted() {
      this.init("");
    },
    methods: {
    async init() {
    this.isLoading = true;
       return await ApiService.assets("&schema_name=rune", (rows) => {
          if (rows) {
              this.$store.commit("addRunes", rows);
              this.isLoading = false;
          }
      })
  },
  async burn(asset_id){
    let laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: "atomicassets",
            name: 'burnasset',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: {"asset_owner":laccount,"asset_id":asset_id},
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await ApiService.doSign(actionData, (res)=>{
              if(res.processed.id){
                this.$store.dispatch("getRunesBalance");
                this.init("");
              }
            }, ApiService.defaultErrorCallback)
        } else {
            return await ApiService.signWithWax({ actions: [actionData] }, (res)=>{
              if(res.processed.id){
                this.$store.dispatch("getRunesBalance");
                this.init("");
              }
            }, ApiService.defaultErrorCallback)
        }
    } catch (err) {
        return ApiService.defaultErrorCallback(err)
    }
  },
  async burnAll(assets){
    let laccount = localStorage.getItem("wax_user");
    try {
        const actionData = [];
        for (let i = 0; i < assets.length; i++) {
          actionData.push({
                account: "atomicassets",
                name: 'burnasset',
                authorization: [{
                    actor: laccount,
                    permission: 'active',
                }],
                data: {"asset_owner":laccount,"asset_id":assets[i].asset_id},
            });
          if(i==9){
            break;
          }
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await ApiService.doSign(actionData, (res)=>{
              if(res.processed.id){
                this.$store.dispatch("getRunesBalance");
              }
            }, ApiService.defaultErrorCallback)
        } else {
            return await ApiService.signWithWax({ actions: actionData }, (res)=>{
              if(res.processed.id){
                this.$store.dispatch("getRunesBalance");
              }
            }, ApiService.defaultErrorCallback)
        }
    } catch (err) {
        return ApiService.defaultErrorCallback(err)
    }
  },
    getImgUrl(hash) {
        let url = 'http://ipfs.io/ipfs/' + hash;
        return '//images.weserv.nl/?url=' + url + "&w=500";
    },
}      
}
</script>
<style scoped>
.price{
  font-size: 14px;
}
#container {
  margin-top: 20%;
}
.mt-10 {
  margin-top: 10%;
}
.btn-success{
  font-size: 15px;
    width: 200px;
    background: #847561;
}
.btn-success:hover{
    background: #998975;
}
</style>