<template>
        <div class="container top-head" id="container">
            <div class="text-center hero_title">
                <h1 class="mt5">{{title}}</h1>
            </div>
        </div>
        <section class="time-sectoion" v-if="!gameOver && counter!=0">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="time-1 w-100">
                        <h1 v-if="counter!='Invalid date'" :class="{ closetime: hoursLeft<0.1 }">{{counter}}</h1>
                        <h1 v-if="counter=='Invalid date'">Loading...</h1>
                        <p v-if="counter=='Invalid date'" class="help-text">if loading takes longer then 5 sec refresh the page.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="time-sectoion" v-if="gameOver">
        <div class="row justify-content-center">
                <div class="col-md-12 col-sm-12">
                    <div class="time-2 d-flex justify-content-center w-100">
                        <h1>{{winningToken}}</h1>
                        <img src="images/round-logo.png" alt="">
                    </div>
                    <h3 class="help-text-bg">Midgard Clash Takes <strong>20% commission</strong> <br />for improving the game</h3>
                </div>
            </div>
        </section>
        <div class="container" v-if="gameOver">
            <div class="row justify-content-center next_btn mt-5">
                <div class="col-sm-12 text-center">
                    <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="claim()">
                        <img src="images/login.png" alt="" class="btn_lg">
                        <div class=" text-center test">
                            <h3 class="text-center">Claim</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
</template>

<script>
import { reactive } from 'vue';
import ApiService from '../_services/Service'
import moment from 'moment';
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
//  components: { Timer },
    name:"Wait",
    data () {
        return {
            countDown : null,
            title:"Battle will end in",
            counter:0,
            gameOver:false,
            winningToken:0,
            hoursLeft:0,
            timeLeft:0,
            duration:0,
            expiredDate:"",
            endTime:"",
            hour:"",
            winningTokenWithoutCom:0,
            t:true
        }
    },
    setup() {
    const authentication = reactive({ user_name: localStorage.getItem('wax_user') });
    return {
      authentication
    };
  },
  mounted() {
    if (!this.gameOver && !this.countDown) {
        this.countDown = setInterval(() => {
            if(this.counter=="0:00:00" || parseFloat(this.hour)<0){
                this.counter = 0;
                this.title = "Battle Ended"
                this.gameOver = true
                clearInterval(this.countDown)
            }
            let currentUtc = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSS')
            if(this.hoursLeft<1){
                this.counter = moment.utc(moment(this.endTime).diff(currentUtc)).format('mm:ss')
            }else{
                this.hour = moment.duration(moment(this.expiredDate).diff(currentUtc)).asHours()
                this.counter = Math.trunc(this.hour)+":"+moment.utc(moment(this.endTime).diff(moment(currentUtc))).format('mm:ss')
            }
        }, 1000);
    } else {
        clearInterval(this.countDown)
    }
    
  },
  methods: {
    async claim(){
        if(this.gameOver || this.counter==0){
          await ApiService.claim(((res)=>{
            if(res.processed){
                localStorage.removeItem("selectedItem");
                localStorage.removeItem("selectedChar");
                let token = Number(this.winningToken).toFixed(8);
                this.$store.commit('updateBalance', token)
                toast.success("Token claimed successfully");
                setTimeout(() => {
                    localStorage.removeItem('gameLogged')
                    this.$router.push("/ready");
                }, 3000);
            }
          }),((error)=>{
            this.error = ApiService.errorFormat(error)
            toast.error(this.error);
          }));
        }else{
            this.error = "Game not over yet.";
            toast.error(this.error);
        }
    },
    async postGameLog(){
        if(localStorage.getItem("selectedItem")){
            let weapons = JSON.parse(localStorage.getItem("selectedItem"));
            weapons = weapons.map(item=>item.asset_id);
            let hero = JSON.parse(localStorage.getItem("selectedChar"))[0].asset_id;
            if(weapons && hero){
                let data = {
                    "username":localStorage.getItem('wax_user'),
                    "weapons":weapons,
                    "duration":this.duration,
                    "userchar":hero,
                    "attackendtime":this.endTime,
                    "reward":this.winningToken,
                    "commission":Number(this.winningToken)/4,
                    "attacktime":this.startTime
                }
                return await ApiService.logGamePlay(data).then(()=>{localStorage.setItem('gameLogged',true)});
            }
        }
    }
  },
    async created () {
        if(!localStorage.getItem('gameLogged')){
            this.$store.dispatch("getBagData");
        }
        await ApiService.getGameProgress(this.authentication.user_name,(res)=>{
            if(res!==false){
                this.winningToken = parseFloat(res.winningToken.replace(/YGD/g, ""))
                this.startTime = res.atk_time
                this.endTime = res.end_time
                this.expiredDate = res.expiredDate
                this.hoursLeft = res.hoursLeft
                this.gameOver = res.gameOver
                this.duration = res.duration
                this.winningTokenWithoutCom = ApiService.niceFloat(Number((20/Number(this.winningToken))*100).toFixed(8))
                if(!localStorage.getItem('gameLogged')){
                    this.postGameLog()
                }
            }else{
                this.$router.push("dashboard")
            }
        });
        if(this.gameOver){
            this.title = "Battle Ended"
            clearInterval(this.countDown)
        }
    }
}
</script>
<style scoped>
.container{
    margin-top: 2%;
}
.top-head{
    margin-top: 10% !important;
}
.closetime{
    color: #28a745;
}
.help-text{
    font-size: 14px;
    color: #cfda96;
}
.help-text-bg{
    font-size: 2rem;
    color: #e9c8a8;
}
.help-text-bg strong{
    color: #cfda96;
}
</style>