<template>
  <transition name="modal fade">
    <div class="modal-mask modal-backdrop">
      <div class="">
        <div class="modal-wrapper">
          <div class="modal-dialogs" role="document">
            <div class="modal-content modal-img">
              <div class="modal-body p-5 overflow-hidden">
                <p v-if="error">{{ error }}</p>
                <div class="text-center">
                  <a
                    href="JavaScript:void(0)"
                    class="btn-cross text-right close-modal"
                    @click="hideModal($event)"
                    >&times;</a
                  >
                  <h1 class="tm5 title-head d-inline-block">
                    How Raiding works
                  </h1>
                  <div class="scroll overflow-y">
                    <div class="row mt-3 raiding align-items-center">
                      <div class="col-md-3">
                        <div class="card character">
                          <div class="character-train_card">
                            <img
                              src="images/gold-char-2.png"
                              alt=""
                              class="character-train__img img-1"
                            />
                            <img
                              src="images/gold-char-2.png"
                              alt=""
                              class="character-train__img img-2"
                            />
                            <img
                              src="images/gold-char-2.png"
                              class="img-3"
                              alt=""
                            />
                            <img
                              src="images/gold-char-2.png"
                              class="img-4"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3 class="text-white">
                          + 10.000 ygd
                          <span class="infotext">=</span>
                        </h3>
                        <h3>One Week</h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <h1>12 Rune Piece</h1>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="row mt-5 raiding align-items-center">
                      <div class="col-md-3">
                        <div class="card character">
                          <div class="character-train_card">
                            <img
                              src="images/gold-char-2.png"
                              alt=""
                              class="character-train__img img-1"
                            />
                            <img
                              src="images/gold-char-2.png"
                              alt=""
                              class="character-train__img img-2"
                            />
                            <img
                              src="images/gold-char-2.png"
                              class="img-3"
                              alt=""
                            />
                            <img
                              src="images/gold-char-2.png"
                              class="img-4"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3 class="text-white">
                          + 10.000 ygd
                          <span class="infotext">=</span>
                        </h3>
                        <h3>One Month</h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <h1>60 Rune Piece</h1>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  props: ["error", "showModel"],
  showModal: true,
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-wrapper {
  overflow: hidden !important;
}
.close-modal {
  font-size: 28px;
  position: absolute;
  top: 28px;
  right: 31px;
  color: #e9c8a8;
}
.modal-dialogs {
  width: 485px;
  margin: auto;
  /* max-width: 700px; */
}

.modal-img {
  background: url("../../public/images/modal-frame-min.png") no-repeat;
  background-size: contain;
  height: 408px;
}
.coin-img img {
  width: 20px !important;
  height: 20px !important;
}
.raiding {
  margin-bottom: 60px;
}
.raiding .character {
  position: relative;
  top: -32px;
}
.img-1 {
  z-index: 2;
  top: -2px;
  left: 6px !important;
}
.img-2 {
  top: 34px;
  left: 30px !important;
}
.img-3 {
  top: 23px;
  left: 23px !important;
}
.img-4 {
  top: 9px;
  left: 14px !important;
}
.raiding .char-pos {
  position: absolute;
  /* top: 37px; */
  padding: 0 !important;
  left: 0px;
}
.raiding .character-train_card img {
  width: 100%;
  height: 111px;
  /* padding: 3px; */
  position: absolute;
  left: 0;
}
.raiding .character-train__img {
  padding: 0;
}
</style>
