<template>
    <div class="card-img-box">
        <template v-if="rec">
            <img 
              v-show="isImageLoaded" 
              :src="getImgUrl(rec.asset.immutable_data.img)" 
              alt="{{rec.asset.immutable.name}}" 
              class="card-list__img" 
              @click.prevent="cardClicked(rec)"
              @load="loaded" 
            />
            <img v-if="!isImageLoaded" class="loading w-100" :src="'/images/loader.gif'">
        </template>
        <template v-else>
            <p>No Image found</p>
        </template>
        <div class="card-price buy-item">
          <div class="coin-img">
            <span class="buy-btn item-price_text text-white">{{formatPrice(rec.price)}}</span>
            <img src="../../public/images/coin-icon.png" alt="" />
          </div>
        </div>
        <h4 class="text-center text-white quota">Daily limit: {{rec.daily_quota}}</h4>
    </div>
</template>
<script>
import ApiService from '../_services/Service'
export default {
    name: 'MarketCard',
    props:["rec",'row','priceFeed'],
    data(){
      return {
        isImageLoaded:false,
        showReadyButton:false,
        price:0
      }
    },
    methods:{
      getImgUrl(pet) {
          return ApiService.getImgUrl(pet)
      },
      loaded() {
          this.isImageLoaded = true;
      },
      cardClicked(rec) {
        this.$emit("card-clicked",rec,this.price);
      },
      formatPrice(val){
        if(this.$store.state.tokenPrice==0){
          this.price = (parseFloat(val)/parseFloat(this.priceFeed)).toFixed(0);
          return this.price;
        }
        this.price = (parseFloat(val)/parseFloat(this.$store.state.tokenPrice)).toFixed(0);
        return this.price; //= new Intl.NumberFormat().format(price);
      }
    }
}
</script>