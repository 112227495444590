<template>
        <BackButton link="training" text="Reset" @click.prevent="goBack()" />
        <div class="container" id="container">
         <section class="card_section battle-card">
            <div class="row justify-content-center ">
                <div class="col-sm-12  col-lg-6 col-md-3 col-xl-3 card_section-first--card" v-if="hero">
                    <div class="card bg-transparent ">
                        <template v-if="'img' in hero.data">
                            <a href="JavaScript:void(0)"><img class="battle-img" v-show="isImageLoaded" :src="getImgUrl(hero.data.img)" alt="{{hero.data.name}}" @load="loaded"></a>
                            <img v-if="!isImageLoaded" class="loading battle-img" :src="'/images/loader.gif'">
                        </template>
                        <template v-else>
                            <p>No Image found</p>
                        </template>
                    </div>
                    <h2 class="ml-50" v-if='hero.data["increase income"]!=0'>%{{hero.data["increase income"]}} increase income</h2>
                    <h2 class="ml-50" v-if='hero.data["reduce time"]!=0'>%{{hero.data["reduce time"]}} reduce time</h2>
                </div>
                <Card v-for="(item,index) in info" :key="index" :row="index" :rec=item :totalAttack=totalAttack />
                <br />
                <div class="col-sm-12">
                    <h1 class="text-center total_attac-text">Total Power {{totalAttack}}</h1>
                </div>
            </div>
         </section>
         <section class="btn_section">
            <div class="row justify-content-center text-center ">
                <div class="col-md-4 col-xl-2 col-sm-6">
                    <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="setDuration(6)">
                        <img src="images/login.png" alt="" class="btn_sm">
                        <div class="position-absolute btn_text">
                            <h3 class="text-center" :class="{ hs: duration==6 }">6 Hours</h3>
                        </div>
                    </a>
                </div>

                <div class="col-md-4 col-xl-2 col-sm-12">
                    <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="setDuration(12)">
                        <img src="images/login.png" alt="" class="btn_sm">
                        <div class="position-absolute btn_text">
                            <h3 class="text-center" :class="{ hs: duration==12 }">12 Hours</h3>
                        </div>
                    </a>
                </div>

                <div class="col-md-4 col-xl-2 col-sm-12">
                    <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="setDuration(24)">
                        <img src="images/login.png" alt="" class="btn_sm">
                        <div class="position-absolute btn_text">
                            <h3 class="text-center" :class="{ hs: duration==24 }">24 Hours</h3>
                        </div>
                    </a>
                </div>
            </div>
        </section>

        <div class="row justify-content-center next_btn">
            <div class="col-sm-12 text-center ml-lg-5">
                <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="attack()">
                    <img src="images/login.png" alt="" class="btn_lg">
                    <div class=" text-center test">
                        <h3 class="text-center btn-text">Attack</h3>
                    </div>
                </a>
            </div>
        </div>
        </div>
</template>

<script>
import router from '../router'
import ApiService from '../_services/Service'
import Card from '../components/Card'
import BackButton from '../components/layout/BackButton'
import * as waxjs from "@waxio/waxjs/dist";
import { reactive } from 'vue';
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_FULL_URL,
    userAccount: localStorage.getItem("wax_user"),
    pubKeys: null,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
 name:"Ready",
 components: {
    Card,
    BackButton
  },
    data () {
        return {
            error:false,
            info:null,
            hero:null,
            duration:null,
            isImageLoaded:false,
            totalAttack:0
        }
    },
    setup() {
    // check authentication details
    const authentication = reactive({ user_name: localStorage.getItem('wax_user') });

    return {
      authentication
    };
  },
    // created () {
    //     if(localStorage.getItem("ual-session-authenticator")=="Wax"){
    //         (async() => {
    //             await this.autoLogin();
    //         })()
    //     }
    // },
    mounted () {
        // console.log(this.$store.state.balance)
        if(!localStorage.getItem('wax_user')) {
            this.$router.push("login");
        }
        if(localStorage.getItem("selectedItem")==null || localStorage.getItem("selectedChar")==null){
          this.getBag()
        }else{
          this.info = JSON.parse(localStorage.getItem("selectedItem"));
          this.hero = JSON.parse(localStorage.getItem("selectedChar"))[0]
          this.info.push(this.hero)
          this.totalAttack = this.info.map(item => (item.data.attack)? item.data.attack:0).reduce((prev, next) => Number(prev) + Number(next));
          this.info.pop()
        }
    },
    methods:{
      loaded() {
          this.isImageLoaded = true;
      },
      setDuration(hr){
        this.duration = hr;
      },
      async autoLogin() {
        if(localStorage.getItem("ual-session-authenticator")=="Wax"){
          let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
          if (isAutoLoginAvailable) {
              this.userAccount = wax.userAccount;
          }else {
              await wax.login();
          }
        }
      },
      getImgUrl(pet) {
          return ApiService.getImgUrl(pet);
      },
      async attack(){
        if(this.duration!=null){
          await ApiService.attack(this.duration,((res)=>{
            if(res.processed){
              toast.success("Attack has been made, redirecting...");
              // localStorage.removeItem("selectedItem");
              // localStorage.removeItem("selectedChar");
              setTimeout(() => {
                this.$router.push("wait");
              }, 2000);
          }
          }),((error)=>{
            this.error = ApiService.errorFormat(error)
            toast.error(this.error);
          }));
        }else{
            this.error = "select duration";
            toast.error(this.error);
        }
      },
      async getBag(){
        ApiService.getUserBag(async (row)=>{
          if(row){
            let weapons = row.weapons.join(', ')
            let nfts = `${weapons},${row.userchar}`
            await ApiService.setCards(nfts,row.userchar).then((res)=>{
              if (res['success']) {
                let rows = res['data']
                var bag = []
                rows.forEach((item) => {
                    if (item.asset_id == row.userchar) {
                        this.hero = item;
                    } else {
                        bag.push(item)
                    }
                })
                this.info = bag
                this.info.push(this.hero)
                this.totalAttack = this.info.map(item => (item.data.attack)? item.data.attack:0).reduce((prev, next) => Number(prev) + Number(next));
                this.info.pop()
            }
            });
          }else{
            router.push('/dashboard')
          }
        })
      },
      async login() {
        if(localStorage.getItem("ual-session-authenticator")=="Wax"){
          try {
              await wax.login();
          } catch (e) {
              console.error(e);
          }
        }
      },
      async goBack(){
      let userAccount = localStorage.getItem("wax_user")
      if(localStorage.getItem("ual-session-authenticator")!="Wax"){
        await ApiService.doSign({
          account: 'midgardtrade',
          name: 'removebag',
          authorization: [{
            actor: userAccount,
            permission: 'active',
          }],
          data: {
            account: userAccount
          },
        },((res)=>{
          if(res.processed){
            toast.success("Bag reset successfully");
            localStorage.removeItem("selectedItem");
            localStorage.removeItem("selectedChar");
            router.push('/dashboard')
          }
        }),((error)=>{
          console.log(error);
          if(error.what){
            this.error = error.what;
          }else{
            this.error = "Transaction failed, try again"
          }
          toast.error(this.error);
        }));
        return;
      }
    // await ApiService.removeBag({account:wax.userAccount});
      if(!wax.api) {
        await this.autoLogin()
      }
      try {
        let result = await wax.api.transact({
        actions: [{
          account: 'midgardtrade',
          name: 'removebag',
          authorization: [{
            actor: wax.userAccount,
            permission: 'active',
          }],
          data: {
            account: wax.userAccount
          },
        }]
      }, {
        blocksBehind: 3,
        expireSeconds: 1200,
      });
      await new Promise(resolve => setTimeout(resolve, 1000));
        if(result.transaction_id){
            toast.success("Bag Reset Successfully");
            localStorage.removeItem("selectedItem");
            router.push('/dashboard')
        }
      } catch (err) {
        this.error = ApiService.errorFormat(err)
        toast.error(this.error);
      }
  },
 }
}
</script>
<style scoped>
  .battle-img{
    width: 188px;
    margin-left: 50px;
  }
#container {
  margin-top: 10% !important;
}
</style>