<template>
  <transition name="modal fade">
    <div class="modal-mask modal-backdrop">
      <div class="">
        <div class="modal-wrapper">
          <div class="modal-dialogs" role="document">
            <div class="modal-content modal-img">
              <div class="modal-body p-5 overflow-hidden">
                <p v-if="error">{{ error }}</p>
                <div class="text-center">
                  <a
                    href="JavaScript:void(0)"
                    class="btn-cross text-right close-modal"
                    @click="hideModal($event)"
                    >&times;</a
                  >
                  <h1 class="tm5 title-head d-inline-block">
                    Select Characters (Max 4) {{cardCount}}
                  </h1>
                  <div class="scroll overflow-y">
                    <div class="container">
                  <div class="row" v-if="myAssets!==null && myAssets.length>0">
                    <div class="col-xs-4 col-md-3 col-ms-6 col-xl-3" v-for="(info,index) in myAssets" :key="index">
                        <div class="card bg-transparent">
                            <div class="" :class="{selected:info.selected}">
                                <template v-if="'img' in info.data">
                                    <a href="JavaScript:void(0)" @click.prevent="addToBag(info,info.selected)"><img class="w-100" v-show="isImageLoaded" :src="getImgUrl(info.data.img)" alt="{{info.data.name}}" @load="loaded"></a>
                                    <img v-if="!isImageLoaded" class="loading w-100" :src="'/images/loader.gif'">
                                </template>
                                <template v-else>
                                    <p>No Image found</p>
                                </template>
                            </div>
                            <div class="card_hover-effect battle_start-1 animation-bottom fadeIn-bottom">
                                <a href="JavaScript:void(0)" class="position-relative" @click.prevent="addToBag(info,info.selected)"></a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <h1 v-if="!myAssets">No record found.</h1>
                <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";
import ApiService from '../_services/Service'
// import RunesCard from '@/components/RunesCard'
export default {
 name:"Runes",
 props:["cards","error"],
 computed: mapState([
  'myAssets',
  'selectedChar'
 ]),
 components: {
    // RunesCard
  },
 data () {
    return {
      isImageLoaded:false,
      showReadyButton:false,
      showModal: true,
      cardCount: 0,
    }
  },
  methods:{
    hideModal(e){
        this.$emit("modal-hide-click",e);
    },
    getImgUrl(pet) {
          return ApiService.getImgUrl(pet)
      },
      loaded() {
          this.isImageLoaded = true;
      },
      addToBag(rec,isSelected){
          if(isSelected){
            isSelected = false;
            this.cardCount -= 1;
            this.$store.commit('removeChar', rec)
          }else{
            isSelected = true;
            if(this.cardCount<4){
              this.cardCount += 1;
              this.$store.commit('addChar', rec)
            }
          }
          if(this.cardCount<=4){
            let character = [rec]
            this.$emit("card-click",character,!isSelected);
          }
      },
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-wrapper {
  overflow: hidden !important;
}
.close-modal {
  font-size: 28px;
  position: absolute;
  top: 5px;
  right: 31px;
  color: #e9c8a8;
}
.close-modal:hover{
 color:brown
}
.modal-dialogs {
  width: 485px;
  margin: auto;
  /* max-width: 700px; */
}

.modal-img {
  background: url("../../public/images/modal-frame-min.png") no-repeat;
  background-size: contain;
  height: 408px;
}
.coin-img img {
  width: 20px !important;
  height: 20px !important;
}
</style>