import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store";
import Toast, { POSITION, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

// assign routes to app
const app = createApp(App).use(router)
const options = {
    position: POSITION.BOTTOM_RIGHT,
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            timeout: 5000,
            hideProgressBar: true,
        },
        [TYPE.INFO]: {
            timeout: 5000,
            hideProgressBar: true,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            hideProgressBar: true,
        }
    }
};
app.use(Toast, options);
app.use(store);
// mounting app
app.mount('#app')