import { createWebHistory, createRouter } from 'vue-router';

//main view
import mainView from './components/Main';

// basic pages
import login from './components/Login';

//pages
import dashboard from './views/Dashboard';
import inventory from './views/Inventory.vue';
import ready from './views/Ready';
import wait from './views/Wait';
import characterSelect from './views/CharacterSelect';
import Guide from './views/Guide';
import Market from './views/Market';
import Training from './views/Training';
import Attack from './views/Attack';
import Train from './views/Train';
import Raiding from './views/Raiding.vue';
import Blending from './views/Blending';
import RunesConversion from './views/RunesConversion';
import DailyWinners from './views/DailyWinners';
import LeaderBoard from './views/LeaderBoard.vue';
import ApiService from './_services/Service';

const routes = [
    // dashbord router
    {
        path: '/',
        redirect: '/dashboard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            props: true,
            component: dashboard,
            meta: { sideBarKey: 'Dashboard' },
        }, ],
    },
    {
        path: '/',
        redirect: '/inventory',
        component: mainView,
        // inventory auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/inventory',
            name: 'inventory',
            props: true,
            component: inventory,
            meta: { sideBarKey: 'Inventory' },
        }, ],
    },
    {
        path: '/ready',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/ready',
            name: 'ready',
            component: ready,
            meta: { sideBarKey: 'Ready' },
        }, ],
    },
    {
        path: '/attack',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/attack',
            name: 'attack',
            component: Attack,
            meta: { sideBarKey: 'attack' },
        }, ],
    },
    {
        path: '/training',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/training',
            name: 'training',
            component: Training,
            meta: { sideBarKey: 'training' },
        }, ],
    },
    {
        path: '/raiding',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/raiding',
            name: 'raiding',
            component: Raiding,
            meta: { sideBarKey: 'raiding' },
        }, ],
    },
    {
        path: '/train',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/train',
            name: 'train',
            component: Train,
            meta: { sideBarKey: 'train' },
        }, ],
    },
    {
        path: '/dailyWinners',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/dailyWinners',
            name: 'dailyWinners',
            component: DailyWinners,
            meta: { sideBarKey: 'dailyWinners' },
        }, ],
    },
    {
        path: '/blending',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/blending',
            name: 'blending',
            component: Blending,
            meta: { sideBarKey: 'blending' },
        }, ],
    },
    {
        path: '/market',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/market',
            name: 'market',
            component: Market,
            meta: { sideBarKey: 'Market' },
        }, ],
    },
    {
        path: '/wait',
        component: mainView,
        props: true,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/wait',
            name: 'wait',
            component: wait,
            meta: { sideBarKey: 'wait' },
        }, ],
    },
    {
        path: '/character',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/character',
            name: 'character',
            component: characterSelect,
            meta: { sideBarKey: 'Character' },
        }, ],
    },
    {
        path: '/RunesConversion',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/RunesConversion',
            name: 'RunesConversion',
            component: RunesConversion,
            meta: { sideBarKey: 'RunesConversion' },
        }, ],
    },
    {
        path: '/guide',
        name: 'Guide',
        component: Guide,
    },
    {
        path: '/login',
        name: 'Login',
        // props: false,
        component: login,
    },
    {
        path: '/leaderBoard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/leaderBoard',
            name: 'leaderBoard',
            component: LeaderBoard,
            meta: { sideBarKey: 'leaderBoard' },
        }, ],
    },
];

const isLogin = function() {
    return ApiService.isLogin();
};
const router = createRouter({
    history: createWebHistory(),
    routes,
});

// export the router
export default router;