<template>
<header class="p-0 m-0 login-header"></header>
<section>
        <div class="back_btn">
            <div class="py-5">
                <a href="JavaScript:void(0)" class="position-relative" @click.prevent="goBack()">
                    <img src="images/btnselect.png" alt="">
                    <div class="position-absolute d-flex btn-text">
                        <i class="fas fa-angle-left mr-2 fa-2x text-white"></i>
                        <h3>Go back</h3>
                    </div>
                </a>
            </div>
        </div>
        </section>
      <main class="">
          
        <div class="container">
            <div class="col-sm-12 hero-logo">
                <div class="text-center hero_title">
                    <h1 class="primary-dark">How to play</h1>
                </div>
            </div>

            <div class="row">
                <section class="dark">
                    <div class="container py-4">
                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-1.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Login</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>After logging in, players would have to choose the wallet that they would like to
                                        play with. For this game, Wax cloud or Anchor would be utilized.</p>
                                </div>
                            </div>
                        </article>
                        
                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-02.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">choose your weapon</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>The players would have to select weapons (maximum 3 for 1 dungeon) from their
                                        weapon chest that would be used throughout the duration of a game season</p>
                                </div>
                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-03.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Choose A character</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>Players would then get to choose the character that they would like to take to
                                        the dungeon. The character and weapon attributes would determine the "number of
                                        attack powers'' that player would get for each game season.</p>
                                </div>

                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-04.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Ready to battle</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p> The option to choose the duration (6 hours, 12 hours, and 24 hours) of the game
                                        would pop up next. </p>
                                </div>

                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Timer</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>The next screen would show a timer that starts counting
                                        down the selected duration.</p>
                                </div>

                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-06.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Game Result</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>The next screen would show a result of the game, players
                                        would have the option of selecting the earned Yggdrasil token.</p>
                                </div>

                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/howToPlay-img-07.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Daily Rewarded</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>NFT drops at the end of each 24 hours by clicking on "claim" at the top right corner of the screen.</p>
                                </div>

                            </div>
                        </article>

                        <article class="postcard dark green">
                            <a class="postcard__img_link" href="JavaScript:void(0)">
                                <img class="postcard__img" src="images/daily-reward.png" alt="Image Title" />
                            </a>
                            <div class="postcard__text">
                                <h1 class="postcard__title green"><a href="JavaScript:void(0)">Withdraw</a></h1>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">
                                    <p>Players could withdraw their Yggdrasil Tokens to their wallets, when they reach
                                        the 3.000 Yggdrasill in the game. If users keep 5.000 Yggdrasill in their
                                        accounts and don't withdraw, they will be rewarded with %10 Reduce Waiting Time
                                        in the game. If users keep 10.000 Yggdrasill in their accounts and don't
                                        withdraw, they will be rewarded with %20 Reduce Waiting Time in the game.
                                        Players can repeat the entire gameplay after the end of a duration.</p>
                                </div>

                            </div>
                        </article>
                    </div>
                </section>


            </div>

        </div>
    </main>
</template>

<script>
export default {
name: 'guide',
    methods: {
        goBack(){
            this.$router.push("/");
        },
    }
}
</script>
<style scoped>
/************===== How To Play Page Start ====**********************/

a, a:hover {
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  #pageHeaderTitle {
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5rem;
  }
  .btn-more {
    background-image: url(/img/btn_cliam.8e3816af.png) !important;
    background-repeat: round;
    padding: 9px;
    font-family: 'myFirstFont';
    color: #110b07;
    font-size: 17px;
  }
  /* Cards */
  .postcard {
    flex-wrap: wrap;
    display: flex;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #ffffff;
  }
  .postcard.dark {
    background-color: #18151f;
    opacity: 0.9;
  }
  .postcard.light {
    background-color: #e1e5ea;
  }
  .postcard .t-dark {
    color: #18151f;
  }
  .postcard a {
    color: inherit;
  }
  .postcard h1, .postcard .h1 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .postcard .small {
    font-size: 80%;
  }
  .postcard .postcard__title {
    font-size: 1.75rem;
    letter-spacing: 2px;
  }
  .postcard .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: contain;
    position: relative;
  }
  .postcard .postcard__img_link {
    display: contents;
  }
  .postcard .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
  }
  .postcard .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .postcard .postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
    position: relative;
    font-family: myFirstFont;
    color: var(--primary-color);
    font-weight: bold !important;
    font-size: 16px;
  }
  .postcard .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    justify-content: center;
  }
  .postcard .postcard__tagbox .tag__item {
    display: inline-block;
    background: rgba(83, 83, 83, 0.4);
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    user-select: none;
    transition: background-color 0.3s;
  }
  .postcard .postcard__tagbox .tag__item:hover {
    background: rgba(83, 83, 83, 0.8);
  }
  .postcard:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 1;
    border-radius: 10px;
  }
  .postcard:hover .postcard__bar {
    width: 100px;
  }
  
  @media screen and (min-width: 769px) {
    .postcard {
      flex-wrap: inherit;
    }
    .postcard .postcard__title {
      font-size: 2rem;
    }
    .postcard .postcard__tagbox {
      justify-content: start;
    }
    .postcard .postcard__img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }
    .postcard .postcard__text {
      padding: 3rem;
      width: 100%;
    }
    .postcard .media.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }
    .postcard:hover .postcard__img {
      transform: scale(1.1);
    }
    .postcard:nth-child(2n+1) {
      flex-direction: row;
    }
    .postcard:nth-child(2n+0) {
      flex-direction: row-reverse;
    }
    .postcard:nth-child(2n+1) .postcard__text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }
    .postcard:nth-child(2n+0) .postcard__text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }
  @media screen and (min-width: 1024px) {
    .postcard__text {
      padding: 2rem 3.5rem;
    }
  
    .postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      top: -20%;
      height: 130%;
      width: 55px;
    }
  
    .postcard.dark .postcard__text:before {
      background: #18151f;
    }
  
    .postcard.light .postcard__text:before {
      background: #e1e5ea;
    }
  }
  /* COLORS */
  .postcard .postcard__tagbox .green.play:hover {
    background: #79dd09;
    color: black;
  }
  
  .green .postcard__title:hover {
    color: #79dd09;
  }
  
  .green .postcard__bar {
    background-color: #79dd09;
  }
  
  .green::before {
    background-image: linear-gradient(-30deg, rgba(121, 221, 9, 0.1), transparent 50%);
  }
  
  .green:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(121, 221, 9, 0.1), transparent 50%);
  }
  
  .postcard .postcard__tagbox .blue.play:hover {
    background: #0076bd;
  }
  
  .blue .postcard__title:hover {
    color: #0076bd;
  }
  
  .blue .postcard__bar {
    background-color: #0076bd;
  }
  
  .blue::before {
    background-image: linear-gradient(-30deg, rgba(0, 118, 189, 0.1), transparent 50%);
  }
  
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(0, 118, 189, 0.1), transparent 50%);
  }
  
  .postcard .postcard__tagbox .red.play:hover {
    background: #bd150b;
  }
  
  .red .postcard__title:hover {
    color: #bd150b;
  }
  
  .red .postcard__bar {
    background-color: #bd150b;
  }
  
  .red::before {
    background-image: linear-gradient(-30deg, rgba(189, 21, 11, 0.1), transparent 50%);
  }
  
  .red:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 21, 11, 0.1), transparent 50%);
  }
  
  .postcard .postcard__tagbox .yellow.play:hover {
    background: #bdbb49;
    color: black;
  }
  
  .yellow .postcard__title:hover {
    color: #bdbb49;
  }
  
  .yellow .postcard__bar {
    background-color: #bdbb49;
  }
  
  .yellow::before {
    background-image: linear-gradient(-30deg, rgba(189, 187, 73, 0.1), transparent 50%);
  }
  
  .yellow:nth-child(2n)::before {
    background-image: linear-gradient(30deg, rgba(189, 187, 73, 0.1), transparent 50%);
  }
  
  @media screen and (min-width: 769px) {
    .green::before {
      background-image: linear-gradient(-80deg, rgba(121, 221, 9, 0.1), transparent 50%);
    }
  
    .green:nth-child(2n)::before {
      background-image: linear-gradient(80deg, rgba(121, 221, 9, 0.1), transparent 50%);
    }
  
    .blue::before {
      background-image: linear-gradient(-80deg, rgba(0, 118, 189, 0.1), transparent 50%);
    }
  
    .blue:nth-child(2n)::before {
      background-image: linear-gradient(80deg, rgba(0, 118, 189, 0.1), transparent 50%);
    }
  
    .red::before {
      background-image: linear-gradient(-80deg, rgba(189, 21, 11, 0.1), transparent 50%);
    }
  
    .red:nth-child(2n)::before {
      background-image: linear-gradient(80deg, rgba(189, 21, 11, 0.1), transparent 50%);
    }
  
    .yellow::before {
      background-image: linear-gradient(-80deg, rgba(189, 187, 73, 0.1), transparent 50%);
    }
  
    .yellow:nth-child(2n)::before {
      background-image: linear-gradient(80deg, rgba(189, 187, 73, 0.1), transparent 50%);
    }
  }
/************===== How To Play Page End ====**********************/
</style>
