<template>
  <header class="p-0 m-0">
    <div class="hero_img d-flex">
      <div class="left_side">
        <div class="left_side-text">
          <Dropdown :items="services" />
          <div class="d-flex ml-4">
            <img src="images/round-logo.png" alt="" class="user-img" />
            <h2 class="mt-3 ml-3 user-name">
              <span class="user-id">{{ logUser }}</span>
            </h2>
          </div>
          <div class="reward_left">
            <div class="d-flex mt-4">
              <h4 class="mt-3">{{ balance }}</h4>
              <img src="images/coin-icon.png" class="logo_img ml-2" alt="" />
            </div>
          </div>
        </div>
        <h5 class="top-note-left">
          CURRENT ADOPTION FACTOR : {{ this.factor }}
        </h5>
      </div>

      <div class="right_side">
        <div class="right_side-text">
          <div class="d-flex justify-content-start align-items-center">
            <!-- <h5>Daily reward</h5> -->
            <div class="img-wrapper">
              <div class="img-content d-flex align-items-center">
                <img src="images/bronze.png" alt="" />
                <h4>{{ bronzeRunePiece }}</h4>
              </div>
            </div>
            <div class="img-wrapper">
              <div class="img-content d-flex align-items-center">
                <img src="images/gold.png" alt="" />
                <h4>{{ goldRunePiece }}</h4>
              </div>
            </div>
            <div class="img-wrapper">
              <div class="img-content d-flex align-items-center">
                <img src="images/silver.png" alt="" />
                <h4>{{ silverRunePiece }}</h4>
              </div>
            </div>
            <a
              v-if="this.rewardValid"
              href="JavaScript:void(0)"
              class="top-claim-btn"
              @click.prevent="getDailyReward()"
              >Claim</a
            >
            <a
              href="JavaScript:void(0)"
              class="top-claim-btn"
              @click.prevent="withdraw()"
              >Withdraw</a
            >
            <a
              href="JavaScript:void(0)"
              class="top-claim-btn"
              @click.prevent="deposit()"
              >Deposit</a
            >
          </div>

          <div class="reward_right">
            <div class="d-flex align-items-center">
              <h4 class="top-balance mt-1">{{ balance }}</h4>
              <img
                src="images/coin-icon.png"
                class="logo_img ml-2 mt-0"
                alt=""
              />
            </div>
            <h5 class="top-note" v-if="reduceTime == 10">10% reduce time</h5>
            <h5 class="top-note" v-if="reduceTime == 20">20% reduce time</h5>
          </div>
        </div>
      </div>
    </div>
  </header>
  <Details
    :error="error"
    v-if="showModal"
    v-on:modal-hide-click="showModal = false"
  />
  <Withdraw
    :error="error"
    v-if="showWModal"
    v-on:w-modal-hide-click="showWModal = false"
  />
</template>

<script>
import router from "../router";
import Details from "../components/Details";
import Withdraw from "../components/Withdraw";
import ApiService from "../_services/Service";
import moment from "moment";
import Dropdown from "./Dropdown";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
const toast = useToast();
export default {
  name: "Navbar",
  components: {
    Details,
    Withdraw,
    Dropdown,
  },
  computed: {
    ...mapGetters([
      "balance",
      "getBagData",
      "reduceTime",
      "goldRunePiece",
      "bronzeRunePiece",
      "silverRunePiece",
    ]),
  },
  props: {
    logUser: {
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showModal: false,
      showWModal: false,
      rewardTime: 0,
      rewardValid: 0,
      counter: 0,
      progress: 0,
      factor: 1,
      leftHours: 0,
      countDown: null,
      amount: 0,
      services: [
        {
          title: "Dashboard",
          link: "/dashboard",
        },
        {
          title: "Inventory",
          link: "/inventory",
        },
        {
          title: "Fight",
          link: "/attack",
        },
        {
          title: "Market",
          link: "/market",
        },
        {
          title: "Training",
          link: "/training",
        },
        {
          title: "Raiding",
          link: "/raiding",
        },
        {
          title: "DailyWinners",
          link: "/dailyWinners",
        },
        {
          title: "Leader Board",
          link: "/leaderBoard",
        },
      ],
    };
  },

  async mounted() {
    this.$store.dispatch("getRunesBalance");
    await ApiService.getUserReward((res) => {
      if (res.rows.length) {
        this.$store.commit("addBalance", res.rows[0].tokens);
      }
    });
    await ApiService.getTable(
      "get_table_rows",
      {
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 1,
        table: "adoptfactor",
      },
      (res) => {
        if (res.rows.length) {
          this.factor = Number(res.rows[0].adoption_factor) / 100 / 100;
        }
      }
    );
    await this.dailyReward();
    await this.checkDailyReward();
  },
  methods: {
    logout: function () {
      router.push("/login");
    },
    async dailyReward(lowerBound = null) {
      await ApiService.dailyWinners(lowerBound, async (res) => {
        if (res.rows.length) {
          const row = res.rows.find(
            (x) => x.account === localStorage.getItem("wax_user")
          );
          if (row) {
            return (this.rewardValid = true);
          } else {
            if (res.more) {
              await this.dailyReward(res.next_key);
            }
          }
        }
      });
    },
    deposit: function () {
      this.showModal = true;
    },
    withdraw: function () {
      this.showWModal = true;
    },
    getDailyReward() {
      if (this.rewardValid) {
        ApiService.rewardClaim(
          async (res) => {
            if (res.processed) {
              ApiService.dailyEarning().then((res) => {
                toast.info("You earneds " + res.token);
              });
              setTimeout(() => {
                this.$router.go();
              }, 3000);
              await this.checkDailyReward();
            }
          },
          (error) => {
            toast.error(ApiService.errorFormat(error));
          }
        );
      }
    },
    async checkDailyReward() {
      let utcNow = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      var today = new Date();
      var startDate =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      // this.rewardTime = moment(res[0].timestamp).add(24, 'hours').format('YYYY-MM-DD HH:mm:ss');
      this.rewardTime = moment(`${startDate} 05:00:00`)
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      this.leftHours = moment
        .duration(moment(this.rewardTime).diff(moment(utcNow)))
        .asHours();
      if (this.leftHours > 0) {
        this.countDown = setInterval(() => {
          let currentUtc = moment.utc().format("YYYY-MM-DD HH:mm:ss");
          let leftHours = moment
            .duration(moment(this.rewardTime).diff(moment(currentUtc)))
            .asHours();
          this.counter = moment
            .utc(moment(this.rewardTime).diff(currentUtc))
            .format("HH:mm:ss");
          this.progress = 100 - (leftHours / 24) * 100;
          if (this.progress > 85) {
            this.progress -= 15;
          }
          if (this.counter == "00:00:00" || this.progress > 85) {
            this.counter = 0;
            this.progress = 100;
            clearInterval(this.countDown);
          }
        }, 1000);
      }
    },
  },
};
</script>
<style>
.top-margin7 {
  margin-top: 7px;
}
.top-note {
  /* margin-left: -26px; */
  margin-top: 0px !important;
  text-align: left;
}
.top-note-left {
  margin-top: 5px;
  margin-left: 87px;
  color: #e9c8a8;
  font-family: myFirstFont;
  /* text-align: left; */
}
.img-wrapper .img-content {
  width: 100%;
  height: 100%;
  margin: 0 10px;
  /* object-fit: cover; */
}
.img-wrapper h4,
.top-balance {
  margin-top: 2px;
  margin-left: 3px;
  color: #fff;
}
.img-wrapper .img img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}
</style>