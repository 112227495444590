<template>
    <div class="col-xs-4 col-md-3 col-ms-6 col-xl-3">
        <div class="card bg-transparent">
            <div class="" :class="{selected:info.selected}">
                <template v-if="'img' in info.data">
                    <a href="JavaScript:void(0)" @click.prevent="addToBag(info,info.selected)"><img class="w-100" v-show="isImageLoaded" :src="getImgUrl(info.data.img)" alt="{{info.data.name}}" @load="loaded"></a>
                    <img v-if="!isImageLoaded" class="loading w-100" :src="'/images/loader.gif'">
                </template>
                <template v-else>
                    <p>No Image found</p>
                </template>
            </div>
            <div class="card_hover-effect battle_start-1 animation-bottom fadeIn-bottom">
                <a href="JavaScript:void(0)" class="position-relative" @click.prevent="addToBag(info,info.selected)"></a>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../_services/Service'
export default {
    name: 'ChooseCard',
    props:["rec",'row'],
    data(){
      return {
        isImageLoaded:false,
        showReadyButton:false
      }
    },
    computed: {
        info: function(){
            return this.rec
        }
    },
    methods:{
      getImgUrl(pet) {
          return ApiService.getImgUrl(pet)
      },
      loaded() {
          this.isImageLoaded = true;
      },
      addToBag(rec,isSelected){
            let selectedCards = this.$store.state.selectedRunes.length;
            let character = rec
            if(rec.schema.schema_name=="weapons"){
              if(selectedCards==0){
                this.$store.commit('addRune', rec)
                this.info.selected = !isSelected
              }else{
                this.$store.commit('removeRune', rec)
                this.info.selected = false
              }
            }
            if(rec.schema.schema_name=="characters"){
              if((rec.data.rarity=="silver" && selectedCards < 3) || (rec.data.rarity=="gold" && selectedCards < 2) || (rec.data.rarity=="bronze" && selectedCards < 4)){
                this.$store.commit('addRune', rec)
                this.info.selected = !isSelected
              }else{
                this.$store.commit('removeRune', rec)
                this.info.selected = false
              }
            }
            this.$emit("card-click",character,!isSelected);
      },

    }
}
</script>

<style>

.btn-primary{
  margin-left: 5px;
}

</style>