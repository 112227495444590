import { createStore } from "vuex";
import ApiService from '../_services/Service'
export default createStore({
    state: {
        tokenPrice: 0,
        balance: 0,
        reduceTime: 0,
        username: null,
        selectedWeapons: null,
        selectedRunes: [],
        selectedChar: [],
        goldRunePiece: 0,
        silverRunePiece: 0,
        bronzeRunePiece: 0,
        runes: null,
        selectedRunesPiece: null,
        runesBalance: 0,
        dailyWinners: null,
        myAssets: null,
        stakes: null,
        userBag: null,
    },
    actions: {
        getBagData: function(state) {
            ApiService.getUserBag(async(row) => {
                if (row) {
                    if (row.weapons) {
                        state.selectedWeapons = row.weapons.join(', ')
                        let nfts = `${state.selectedWeapons},${row.userchar}`
                        state.userBag = await ApiService.setCards(nfts, row.userchar)
                    }
                }
            })
        },
        getRunes: async({ commit }, params) => {
            return await ApiService.assets(params, (rows) => {
                if (rows) {
                    commit("addRunes", rows);
                }
            })
        },
        getMyAssets: function({ commit }, params) {
            ApiService.getAssets(params, (rows) => {
                if (rows) {
                    commit("setMyAssets", rows);
                }
            })
        },
        getMyStakes: function({ commit }) {
            ApiService.getRaid((res) => {
                commit("setMyStakes", res);
            });
        },
        getUserAvatar: function({ commit }, user) {
            ApiService.userPhoto(user, (res) => {
                if (res.rows.length) {
                    commit("updateDailyWinnerAvatar", { account: user, image: res.rows[0].photo_hash });
                }
            });
        },
        getTempateImage: function({ commit }, params) {
            ApiService.getByTempleteId(params.reward, (res) => {
                if (res.data.data) {
                    commit("updateRewardTemplate", { account: params.account, image: res.data.data.immutable_data.img });
                }
            });
        },
        getRunesBalance: function({ commit }) {
            ApiService.getRuesBalance((res) => {
                if (res) {
                    commit("addRunePieces", res.rows[0]);
                }
            });
        }
    },
    mutations: {
        setMyAssets: function(state, rows) {
            state.myAssets = rows
        },
        setMyStakes: function(state, rows) {
            // state.stakes = rows
            // if (rows) {
            //     rows.map((item, index) => {
            //         item.chars.map((i, d) => {
            //             console.log(d);
            //             rows[index].chars[d] = `images/templates/${i}.png`
            //             return i
            //         })
            //         return item
            //     })
            if (state.stakes) {
                state.stakes = [...state.stakes, ...rows];
            } else {
                state.stakes = rows;
            }
            // }
        },
        addRunes(state, val) {
            if (val) {
                state.runes = val
                    // state.goldRunePiece = state.runes.filter(el => el.template.template_id === "389739");
                    // state.bronzeRunePiece = state.runes.filter(el => el.template.template_id === "389737");
                    // state.silverRunePiece = state.runes.filter(el => el.template.template_id === "389738");
            }
        },
        addRunePieces(state, val) {
            if (val) {
                for (let i = 0; i < val.balances.length; i++) {
                    const elm = val.balances[i];
                    if (elm.key == "0,BRUN") {
                        state.bronzeRunePiece = elm.value;
                    } else if (elm.key == "0,GRUN") {
                        state.goldRunePiece = elm.value;

                    } else if (elm.key == "0,SRUN") {
                        state.silverRunePiece = elm.value;
                    }
                    state.runesBalance += elm.value
                }
            }
        },
        addDailyWinners(state, val) {
            if (val) {
                if (val) {
                    val.map((user, index) => {
                        val[index].avatar = "images/round-logo.png";
                        val[index].template = `images/templates/${user.reward}.png`
                        this.dispatch("getUserAvatar", user.account);
                        return user
                    })
                }
                if (state.dailyWinners) {
                    state.dailyWinners = state.dailyWinners.push(val);
                } else {
                    state.dailyWinners = val;
                }
            }
        },
        updateDailyWinnerAvatar(state, val) {
            if (val) {
                if (state.dailyWinners) {
                    state.dailyWinners.map((user, index) => {
                        if (val.account == user.account) {
                            state.dailyWinners[index].avatar = ApiService.getImgUrl(val.image);
                        }
                        return user
                    })
                }
            }
        },
        updateRewardTemplate(state, val) {
            if (val) {
                if (state.dailyWinners) {
                    state.dailyWinners.map((user, index) => {
                        if (val.account == user.account) {
                            state.dailyWinners[index].template = ApiService.getImgUrl(val.image);
                        }
                        return user
                    })
                }
            }
        },
        addBalance(state, val) {
            val = parseFloat(val.toString());
            state.balance = val
        },
        addUser(state, val) {
            state.username = Number(val)
        },
        updateBalance(state, val) {
            state.balance = Number(state.balance) + Number(val)
        },
        decrementBalance(state, val) {
            state.balance = Number(state.balance) - Number(val)
        },
        setTokenPrice(state, val) {
            val = parseFloat(val.toString());
            state.tokenPrice = parseFloat(val)
        },
        addRune(state, val) {
            if (state.selectedRunes) {
                let alreadyExist = state.selectedRunes.includes(val);
                if (!alreadyExist) {
                    state.selectedRunes.push(val)
                } else {
                    state.selectedRunes = state.selectedRunes.filter(el => el.asset_id !== val.asset_id);
                }
            } else {
                state.selectedRunes = [val]
            }
        },
        addPiece(state, val) {
            state.selectedRunesPiece = val
        },
        clearPieces(state) {
            state.selectedRunesPiece = false
        },
        removeRune(state, val) {
            if (val) {
                state.selectedRunes = state.selectedRunes.filter(el => el.asset_id !== val.asset_id);
            }
        },
        addChar(state, val) {
            if (state.selectedChar) {
                let alreadyExist = state.selectedChar.includes(val);
                if (!alreadyExist) {
                    state.selectedChar.push(val)
                } else {
                    state.selectedChar = state.selectedChar.filter(el => el.asset_id !== val.asset_id);
                }
            } else {
                state.selectedChar = [val]
            }
            state.myAssets = state.myAssets.map((item) => {
                if (item.asset_id === val.asset_id) {
                    item.selected = true;
                }
                return item;
            });
        },
        removeChar(state, val) {
            if (val) {
                state.selectedChar = state.selectedChar.filter(el => el.asset_id !== val.asset_id);
                state.myAssets = state.myAssets.map((item) => {
                    if (item.asset_id === val.asset_id) {
                        item.selected = false;
                    }
                    return item;
                });
            }
        },
        clearRune(state) {
            state.selectedRunes = [];
        }
    },
    getters: {
        balance: state => {
            if (state.balance) {
                let numberVal = state.balance
                return new Intl.NumberFormat().format(numberVal)
            }
            return 0;
        },
        tokenPrice: state => {
            if (state.tokenPrice) {
                let numberVal = state.tokenPrice
                return parseFloat(numberVal)
            }
            return 0;
        },
        reduceTime: state => {
            if (state.balance > 4999 && state.balance < 10000) {
                return 10;
            }
            if (state.balance >= 10000) {
                return 20;
            }
            return 0;
        },
        runes: state => {
            if (state.runes !== null)
                return state.runes = state.runes.filter(el => el.data.description === "Rune Pieces are using for create the Runes.");
        },
        myAssets: state => state.myAssets,
        username: state => state.username,
        selectedWeapons: state => state.selectedWeapons,
        userBag: state => state.userBag,
        selectedRunes: state => state.selectedRunes,
        selectedRunesPiece: state => {
            if (state.selectedRunesPiece) {
                return state.selectedRunesPiece.slice(0, 5);
            }
        },
        stakes: state => state.stakes,
        dailyWinners: state => {
            if (state.dailyWinners) {
                return state.dailyWinners
                    // state.dailyWinners.map((user, index) => {
                    //     state.dailyWinners[index].avatar = "images/round-logo.png";    
                    //     return user
                    // })
            }
        },
        bronzeRunePiece: state => state.bronzeRunePiece,
        silverRunePiece: state => state.silverRunePiece,
        goldRunePiece: state => state.goldRunePiece,
        runesBalance: state => state.runesBalance,
        selectedChar: state => state.selectedChar
    }
});