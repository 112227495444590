import axios from 'axios'
import * as waxjs from "@waxio/waxjs/dist";
import Link from 'anchor-link'
import { isWebUri } from 'valid-url';
import moment from 'moment';
var https = require('https');
import { useToast } from "vue-toastification";
const toast = useToast();
import BrowserTransport from 'anchor-link-browser-transport'
var keys = null
var laccount = null
if (localStorage.getItem("ual-wax:autologin") != null) {
    let ualWax = JSON.parse(localStorage.getItem("ual-wax:autologin"))
    keys = ualWax ? ualWax.pubKeys : null
}
if (localStorage.getItem("wax_user") != null) {
    laccount = localStorage.getItem("wax_user")
}
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_FULL_URL,
    userAccount: localStorage.getItem("wax_user"),
    pubKeys: keys,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);
const assetUrl = process.env.VUE_APP_API_ASSET_URL;
const setCards = async(params, charId) => {
    return await axios
        .get(`${assetUrl}?owner=${localStorage.getItem('wax_user')}&page=1&ids=${params}`)
        .then((response) => {
            let res = response['data']
            if (res['success']) {
                let rows = res['data']
                var bag = []
                rows.forEach((item) => {
                    if (item.asset_id == charId) {
                        localStorage.setItem("selectedChar", JSON.stringify([item]));
                    } else {
                        bag.push(item)
                    }
                })
                localStorage.setItem("selectedItem", JSON.stringify(bag));
            }
            return res;
        })
}
const getImgUrl = (hash, size = "&w=500&h=500") => {
    if (isWebUri(hash)) {
        return hash;
    }
    // return 'https://resizer.atomichub.io/images/v1/preview?ipfs=' + hash + "&size=370";
    let url = 'http://ipfs.io/ipfs/' + hash;
    // return url
    // return 'https://images.hive.blog/300x0/' + url;
    return '//images.weserv.nl/?url=' + url + size;
}
const callApi = async(params, callback) => {
    return await axios
        .get(`${assetUrl}?owner=${localStorage.getItem('wax_user')}&page=1&limit=1000${params}`)
        .then((res) => res.data.data)
        .then(callback)
}
const genericCall = async(params, callback) => {
    return await axios
        .get(
            `${assetUrl}?owner=${localStorage.getItem(
            "wax_user"
          )}&${params}`
        )
        .then(callback)
}
const getRune = async(rarity, callback) => {
    await genericCall(`page=1&limit=1000&data:text.rarity=${rarity}&schema_name=rune`, (res) => {
            if (res !== null)
                return res.data.data.find(el => el.data.description !== "Rune Pieces are using for create the Runes.");
        })
        .then(callback)
}
const assets = async(params, callback) => {
    await callApi(`&order=desc&collection_name=${process.env.VUE_APP_COLLECTION}${params}`, callback)
}
const getByTempleteId = async(tmpId, callback) => {
    return axios
        .get(`${process.env.VUE_APP_API_TEMPLATE_URL}/midgardtrade/${tmpId}`)
        .then((res) => callback(res))
}
const removeBag = async(data, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'removebag',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: data,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({
                actions: [actionData]
            }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const link = new Link({
    transport: new BrowserTransport({ requestStatus: false }),
    chains: [{
        chainId: process.env.VUE_APP_CHAIN_ID,
        nodeUrl: process.env.VUE_APP_CHAIN_FULL_URL,
    }],
})
const getGameProgress = async(account, callBack) => {
    laccount = localStorage.getItem("wax_user");
    const data = {
        json: true, // Get the response as json
        code: process.env.VUE_APP_CONTRACT, // Contract that we target
        scope: account, // Account that owns the data
        table: "useratk", // Table name
        limit: 1, // Maximum number of rows that we want to get
        reverse: false, // Optional: Get reversed data
        show_payer: false // Optional: Show ram payer
    }
    return await wax.rpc.get_table_rows(data).then((res) => {
        if (res.rows.length) {
            let final = {
                winningToken: res.rows[0].winning_token,
                atk_time: res.rows[0].atk_time,
                end_time: res.rows[0].end_time,
                expiredDate: moment.utc(res.rows[0].end_time).format('YYYY-MM-DDTHH:mm:ss.SSSS'),
                currentUtc: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSS'),
                gameOver: true,
                duration: res.rows[0].duration,
                hoursLeft: 0
            }
            final.gameOver = moment(final.currentUtc).isAfter(moment(final.expiredDate))
            final.hoursLeft = moment.duration(moment(final.expiredDate).diff(moment(final.currentUtc))).asHours()
            return final;
        }
        return false

    }).then(callBack)
}
const getAccount = async(callBack) => {
    laccount = localStorage.getItem("wax_user");
    return await axios
        .post(`${process.env.VUE_APP_CHAIN_API}/get_account`, {
            account_name: laccount ? laccount : localStorage.getItem("wax_user"),
        }).then(callBack)
}
const getTable = async(path, data, callBack) => {
    if (path == "get_table_rows")
        return await wax.rpc.get_table_rows(data).then(callBack)
}
const getUserReward = async(callBack) => {
    return await getTable('get_table_rows', {
        scope: localStorage.getItem('wax_user'),
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 1,
        table: process.env.VUE_APP_TBL_USER_REWARD
    }).then(callBack)
}
const priceFeed = async(callBack) => {
    return await getTable('get_table_rows', {
            scope: process.env.VUE_APP_CONTRACT,
            code: process.env.VUE_APP_CONTRACT,
            index_position: 1,
            json: true,
            limit: 1,
            table: process.env.VUE_APP_TBL_PRICE_FEED
        }).then((res) => res.rows[0])
        .then(callBack)
}
const dailyEarning = async(callBack, lowerBound = null) => {
    await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        lower_bound: lowerBound,
        limit: 1000,
        table: "dailyearning"
    }).then(async(res) => {
        const row = res.rows.find(x => x.account === localStorage.getItem('wax_user'))
        if (row) {
            return row;
        }
        if (res.more === true) {
            await dailyEarning(callBack, res.next_key);
        }
        return res;
    }).then(callBack)
}
const market = async(callBack, lowerBound = null) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        lower_bound: lowerBound,
        limit: 1000,
        table: process.env.VUE_APP_TBL_MARKET
    }).then(async(res) => {
        const requests = res.rows.map(async(val, index) => {
            let nfts = await getByTempleteId(val.asset_t, (res) => res.data.data)
            res.rows[index].asset = nfts
            return val
        });
        if (res.more === true) {
            await market(callBack, res.next_key);
        }
        return Promise.all(requests).then(() => {
            return res.rows
        });
    }).then((res) => callBack(res))
}
const getUserBag = async(callBack, lowerBound = null) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 1000,
        table: process.env.VUE_APP_TBL_BAG,
        lower_bound: lowerBound
    }).then(async(res) => {
        const row = res.rows.find(x => x.account === localStorage.getItem('wax_user'))
        if (row) {
            return row;
        }
        if (res.more === true) {
            await getUserBag(callBack, res.next_key);
        }
        return res;
    }).then(callBack)
}
const getRaid = async(callBack, lowerBound = null) => {
    return await wax.rpc.get_table_rows({
        scope: localStorage.getItem('wax_user'),
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 5,
        table: "userstaking",
        lower_bound: lowerBound
    }).then((res) => callBack(res))
}
const getRuesBalance = async(callBack) => {
    return await wax.rpc.get_table_rows({
        scope: localStorage.getItem('wax_user'),
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 1,
        table: "user.info",
        lower_bound: null
    }).then((res) => callBack(res))
}
const doSign = async(action, onSuccessCallback, onErrorCallback) => {
    await link.restoreSession('midgardclash').then((session) => {
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
    }).catch(async(error) => {
        console.log(error);
        const identity = await link.login('midgardclash')
        const { session } = identity
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
        return error;
    })
}
const signWithWax = async(action, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
    if (!isAutoLoginAvailable) {
        await wax.login();
    }
    try {
        await wax.api.transact(action, {
            blocksBehind: 3,
            expireSeconds: 120,
            broadcast: true,
            sign: true,
        }).then(onSuccessCallback).catch(onErrorCallback)
    } catch (error) {
        return onErrorCallback(error)
    }
}
const attack = async(duration, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'attack',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: {
                account: laccount,
                atk_duration: duration
            },
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({
                actions: [actionData]
            }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const claim = async(onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'claimtoken',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: {
                account: laccount
            },
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (error) {
        onErrorCallback(error)
    }
}
const rewardClaim = async(onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'claimdaily',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: {
                account: laccount
            },
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (error) {
        onErrorCallback(error)
    }
}
const withdraw = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'withdraw',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const buy = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'buynft',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const deposit = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_TOKEN_CONTRACT,
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            return await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const train = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: "atomicassets",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            return await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const craft = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: process.env.VUE_APP_CONTRACT,
            name: 'rune.craft',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            return await signWithWax({ actions: [actionData] }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const defaultErrorCallback = (error) => {
    toast.error(errorFormat(error));
}
const defaultSuccessCallback = (res) => {
    if (res.processed) {
        toast.success("Transaction has been completed.");
        return res;
    }
}
const isLogin = () => {
    // check local store and also expiry of session
    if (localStorage.getItem('wax_user')) {
        var sessionDate = localStorage.getItem("ual-session-expiration");
        var d1 = new Date();
        var d2 = new Date(sessionDate);
        return moment(d2).isAfter(moment(d1))
    } else {
        return false
    }
}
const calculateTime = (balance, duration) => {
    if (balance > 3000 && balance < 5000) {
        return Number(duration) - (Number(duration) * 0.1);
    }
    if (balance > 9999) {
        return Number(duration) - (Number(duration) * 0.2);
    }
    return duration;
}
const dailyWinners = async(lowerBound, callBack) => {
    return await getTable('get_table_rows', {
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        lower_bound: lowerBound,
        limit: 1000,
        table: "dailywinners"
    }, callBack)
}
const userPhoto = async(user, callBack) => {
    return await getTable('get_table_rows', {
        code: "wax.gg",
        index_position: 1,
        json: true,
        limit: 10,
        lower_bound: user,
        reverse: false,
        scope: "wax.gg",
        show_payer: false,
        table: "photos",
        table_key: "",
        upper_bound: user
    }, callBack)
}
const errorFormat = (error) => {
    console.log(error.message, typeof error);
    if (typeof error == "string") {
        console.log(error);
        return "Something went wrong."
    }
    if (!error.error && error.message) {
        return error.message
    }
    if (error.error.what) {
        if (error.error.details) {
            return error.error.details[0].message
        }
        return error.error.what;
    } else {
        return "Transaction failed,try again."
    }
}
const actionCall = async(callback) => {
    return await axios
        .get(`https://wax.pink.gg/v2/history/get_actions?account=${localStorage.getItem('wax_user')}&filter=${process.env.VUE_APP_CONTRACT}%3Adailyclaim&skip=0&limit=1&sort=desc`)
        .then(res => res.data.actions)
        .then(callback)
}
const logLogin = async(username) => {
    return await axios
        .post(`http://support.midgard.cards/api/login`, { name: username })
        .then(res => res)
}
const logGamePlay = async(data) => {
    return await axios
        .post(`http://support.midgard.cards/api/gp`, data)
        .then(res => res)
}
const leaderBoardEntry = async(data) => {
    return await axios
        .post(`http://support.midgard.cards/api/lbentry`, data)
        .then(res => res)
}
const agent = new https.Agent({
    rejectUnauthorized: false
});
const saveActivity = async(data) => {
    return await axios
        .post(process.env.VUE_APP_OFF_CHAIN, data, { httpsAgent: agent })
        .then(res => res)
}
const saveActivity2 = async(data) => {
    return await axios
        .post(" https://node.midgard.cards/midgardtrade-backend/api/activities", data, { httpsAgent: agent })
        .then(res => res)
}
const niceFloat = (numberVal) => {
    numberVal = numberVal.replace(/YGD/g, "")
    return parseFloat(numberVal.toString());
}
const removeCurrencySymbol = async(numberVal) => {
    return numberVal.replace(/YGD/g, "");
}
const makeRequestParams = (userFilter, searchTerm) => {
    let params = "";
    if (searchTerm) {
        params += "&match=" + searchTerm;
    }
    // if (collection != "collection") {
    //     params += "&collection_name=" + collection;
    // }
    return params;
}
const getAssets = async(params, callback) => {
    return axios
        .get(`${assetUrl}?owner=${localStorage.getItem('wax_user')}&page=1&limit=1000&collection_name=${process.env.VUE_APP_COLLECTION}&schema_name=characters${params}`)
        .then(response => {
            let res = response['data']
            if (res['success']) {
                callback(res["data"]);
                return res['data']
            }
        })
}
export default {
    getAssets,
    deposit,
    saveActivity,
    saveActivity2,
    errorFormat,
    actionCall,
    dailyWinners,
    market,
    claim,
    withdraw,
    doSign,
    attack,
    calculateTime,
    isLogin,
    getGameProgress,
    getTable,
    getAccount,
    removeBag,
    getRaid,
    setCards,
    callApi,
    signWithWax,
    rewardClaim,
    makeRequestParams,
    genericCall,
    removeCurrencySymbol,
    getImgUrl,
    logLogin,
    logGamePlay,
    getUserReward,
    getByTempleteId,
    dailyEarning,
    getUserBag,
    train,
    priceFeed,
    buy,
    assets,
    userPhoto,
    getRune,
    defaultErrorCallback,
    defaultSuccessCallback,
    leaderBoardEntry,
    getRuesBalance,
    craft,
    niceFloat
}