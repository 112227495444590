<template>
  <div class="dropdown-wrapper">
    <div class="menu-item" @click="menuItemClicked(isOpen)">
      <a href="#">
        <img
          src="../../public/images/dropdown-button.png"
          class="dropdown-img"
          alt=""
        />
        <button
          v-if="hideShow"
          key="on"
          @click="hideShow = false"
          class="bg-transparent border-0 btn-outline-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <button
          v-else
          key="of"
          @click="hideShow = true"
          class="bg-transparent border-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </a>
      <transition name="fade" appear>
        <div class="sub-menu" v-if="isOpen">
          <img
            src="../../public/images/dropdown-frame.png"
            alt=""
            class="sub-menu__dropdown-frame"
          />
          <div class="sub-menu__text">
            <div v-for="(item, i) in items" :key="i" class="menu-item">
              <router-link :to="item.link">
              {{ item.title }}
              </router-link>
            </div>
            <a href="#" class="menu-item" @click.prevent="logout()">Logout</a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "dropdown",
  props: ["title", "items"],
  data() {
    return {
      isOpen: false,
      hideShow: false,
    };
  },
  methods: {
    logout: function () {
      router.push("/login");
    },
    menuItemClicked:function(isOpen){
      this.isOpen = !isOpen;
      if(!isOpen){
        this.hideShow = true
      }else{
        this.hideShow = false
      }
    }
  },
};
</script>

