<template>
  <div class="container mt-10">
    <div class="row">
      <!-- Search -->
      <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
        <div class="position-relative">
          <img src="images/field.png" alt="" />
          <div
            class="dropdown position-absolute d-flex"
            @click.prevent="activeMenuSearch = !activeMenuSearch"
          >
            <h3>{{ searchTerm }}</h3>
            <i class="fas fa-angle-down"></i>
          </div>
          <!-- Dropdown menu -->
          <div
            class="dropdown-menu"
            :class="{ 'active-menu': activeMenuSearch }"
          >
            <ul class="nav">
              <a
                @click.prevent="searchAsset('All Weapons')"
                href="JavaScript:void(0)"
                >All Weapons<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('All Characters')"
                href="JavaScript:void(0)"
                >All Characters<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('All Runes')"
                href="JavaScript:void(0)"
                >All Runes<img src="images/choose-icon.png" class="dp_img"
              /></a>
            </ul>
          </div>
        </div>
      </div>
      <!-- Hero Text -->
      <div class="col-lg-4 col-md-6 col-sm-12 hero-logo">
        <div class="text-center hero_title">
          <h1 class="head-title">Inventory</h1>
        </div>
      </div>
      <!-- DropDown -->
      <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
        <div class="position-relative">
          <img src="images/field.png" alt="" />
          <div
            class="dropdown position-absolute d-flex"
            @click.prevent="activeMenu = !activeMenu"
          >
            <h3>{{ rarity }}</h3>
            <i class="fas fa-angle-down"></i>
          </div>
          <!-- Dropdown menu -->
          <div class="dropdown-menu" :class="{ 'active-menu': activeMenu }">
            <ul class="nav">
              <a @click.prevent="setRarity('all')" href="JavaScript:void(0)"
                >All<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('bronze')" href="JavaScript:void(0)"
                >BRONZE<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('silver')" href="JavaScript:void(0)"
                >SILVER<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('gold')" href="JavaScript:void(0)"
                >GOLD<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="setRarity('platinum')"
                href="JavaScript:void(0)"
                >PLATINUM<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="setRarity('ownership')"
                href="JavaScript:void(0)"
                >OWNERSHIP<img src="images/choose-icon.png" class="dp_img"
              /></a>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    />
    <div class="row align-items-center justify-content-center no-gutters">
      <div
        class="col-lg-3 col-md-4 col-sm-6"
        v-for="(item, index) in info"
        :key="index"
      >
        <div class="inventory-box">
          <div class="card">
            <input
              type="checkbox"
              :id="'card' + index"
              class="more"
              aria-hidden="true"
            />
            <div class="content">
              <div
                class="front"
                :style="{
                  backgroundImage: 'url(' + getImgUrl(item.data.img) + ')',
                }"
              >
                <div class="inner">
                  <label
                    :for="'card' + index"
                    class="button"
                    aria-hidden="true"
                  >
                    Details
                  </label>
                </div>
              </div>
              <div class="back">
                <div class="inner">
                  <div class="info">
                    <span>{{ item.data.defense || 0 }}</span>
                    <div class="icon">
                      <i class="fas fa-users"></i>
                      <span>Defense</span>
                    </div>
                  </div>
                  <div class="info">
                    <span>{{ item.data.attack || 0 }}</span>
                    <div class="icon">
                      <i class="fas fa-door-open"></i>
                      <span>Attack</span>
                    </div>
                  </div>
                  <div class="info">
                    <span>{{ item.data["increase income"] || 0 }}</span>
                    <div class="icon">
                      <i class="fas fa-bed"></i>
                      <span>+ income</span>
                    </div>
                  </div>
                  <div class="info">
                    <span>{{ item.data["reduce time"] || 0 }}</span>
                    <div class="icon">
                      <i class="fas fa-bath"></i>
                      <span>- time</span>
                    </div>
                  </div>
                  <div class="description">
                    <p>
                      {{ item.data.name }}
                    </p>
                    <p>
                      {{ item.data.description }}
                      {{ item.data.desciription }}
                    </p>
                  </div>
                  <div class="location">
                    <h1>Card Details</h1>
                  </div>
                  <div class="price">Rarity : {{ item.data.rarity }}</div>
                  <label
                    :for="'card' + index"
                    class="button return"
                    aria-hidden="true"
                  >
                    back
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import ApiService from "../_services/Service";
import Loading from 'vue-loading-overlay';
export default {
    name:"Inventory",
    components:{
    Loading
  },
  data () {
    return {
      currentRoute: null,
      userFilter: null,
      authenticator: null,
      userCollection: process.env.VUE_APP_COLLECTION,
      showReadyButton: false,
      info: null,
      apiRes: null,
      collections: null,
      searchTerm: "All Weapons",
      isLoading: false,
      fullPage: true,
      status: false,
      assetId:"",
      row:{},
      assetIds:[],
      showModal: false,
      showStackModal: false,
      error:false,
      rarity:"All RARITY",
      activeMenuSearch:false,
      activeMenu:false
    }
  },
    mounted() {
      this.init("");
    },
    methods: {
    init(params) {
    this.isLoading = true
    ApiService.genericCall(`page=1&limit=100&collection_name=${process.env.VUE_APP_COLLECTION}&${params}`,(response => {
        let res = response['data']
        if(res['success']) {
          this.apiRes = res['data']
          this.info = res['data']
          this.status = true
        } else {
          this.info = []
          this.status = false
        }
        this.loading = false;
        this.isLoading = false
      }))
  },
  searchAsset(term){
      this.searchTerm = term
      this.activeMenuSearch=false
      if(term=="All Weapons"){
        this.init("&schema_name=weapons");
      }else if(term=="All Characters"){
        this.init("&schema_name=characters");
      }else{
        this.init("&schema_name=rune");
      }
    },
    setRarity(val){
      this.rarity = val;
      this.activeMenu=false
      if(val=="all"){
        this.info = this.apiRes
      }else{
        this.info = this.apiRes.filter((item)=>{
          let coll = item.data.rarity;
            return coll==val
        });
      }
    },
    search(){
    const params = ApiService.makeRequestParams(this.userFilter,this.searchTerm,this.userCollection);
    this.init(params);
  },
    sortChanged(event){
      this.userFilter = event.target.value
      this.search();
    },
    getImgUrl(hash) {
        let url = 'http://ipfs.io/ipfs/' + hash;
        return '//images.weserv.nl/?url=' + url + "&w=500";
    },
}      
}
</script>
<style scoped>
.price{
  font-size: 14px;
}
#container {
  margin-top: 20%;
}
.mt-10 {
  margin-top: 10%;
}
</style>