<template>
    <div class="col-sm-12 col-md-2 col-lg-6 col-xl-2">
        <div class="card bg-transparent">
            <div class="card_img">
                <template v-if="'img' in rec.data">
                    <a href="JavaScript:void(0)"><img class="w-100" v-show="isImageLoaded" :src="getImgUrl(rec.data.img)" alt="{{rec.data.name}}" @load="loaded"></a>
                    <img v-if="!isImageLoaded" class="loading w-100" :src="'/images/loader.gif'">
                </template>
                <template v-else>
                    <p>No Image found</p>
                </template>
            </div>
            <div class="card_hover-effect battle_start-1 animation-bottom fadeIn-bottom">
                <a href="JavaScript:void(0)" class="position-relative">
                    <img src="images/btnselect.png" alt="">
                    <div class="card_text battle_start-text">
                        <h3>AtK {{rec.data.attack}}</h3>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../_services/Service'
// import VueFlip from 'vue-flip';
export default {
    name: 'Card',
    props:["rec",'row','totalAttack'],
    data(){
      return {
        isImageLoaded:false,
        showReadyButton:false
      }
    },
    methods:{
      getImgUrl(pet) {
          return ApiService.getImgUrl(pet)
      },
      loaded() {
          this.isImageLoaded = true;
      },
    }
}
</script>