<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body" >
                <p v-if="error">{{error}}</p>
                <div>
                    <a href="JavaScript:void(0)" class="btn-cross remove" @click="hideModal($event)">&times;</a>
                    <h3 class="tm5 title-head">Withdraw Yggdrasil</h3>
                </div>
                <br />
                    <form method="POST">
                        <div class="search position-relative d-flex">
                            <img src="images/field.png" alt="">
                            <input type="text" class="form-control search-bar" placeholder="Enter to account"
                            v-model="toAccount"
                            >
                        </div>
                        <div class="search position-relative d-flex">
                            <img src="images/field.png" alt="">
                            <input type="number" class="form-control search-bar" placeholder="Enter Amount"
                            v-model="amount"
                            >
                        </div>
                          <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="transfer()">
                              <img src="images/login.png" alt="" class="btn_sm">
                              <div class="position-absolute btn_text">
                                  <h3 class="text-center">Withdraw</h3>
                              </div>
                          </a>
                    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>
<script>
import ApiService from "../_services/Service";
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
 name:"Details",
 props:["error",'showModel'],
 data () {
    return {
      showModal: true,
      toAccount: "",
      amount:0
    }
  },
  mounted() {
    this.toAccount = localStorage.getItem("wax_user")
  },
  methods:{
    hideModal(e){
        this.$emit("w-modal-hide-click",e);
    },
    async transfer(){
        if(this.amount && this.toAccount){
          await ApiService.withdraw({
              account: this.toAccount,
              token: `${Number(this.amount).toFixed(8)} ${process.env.VUE_APP_TOKEN_NAME}`,
          },((res)=>{
            if(res.processed){
                this.$store.commit('decrementBalance', this.amount)
                toast.success("Token withdraw successfully");
                this.$emit("w-modal-hide-click",res);
            }
          }),((error)=>{
            error = ApiService.errorFormat(error)
            toast.error(error);
          }));
        }else{
            let error = "Please provide input data";
            toast.error(error);
        }
    }
  }
}
</script>

<style scoped>
.modal-dialog{
  width: 280px !important;
  max-width: 550px;
}
.search{
  margin-bottom: 4px;
}
.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    /* height: 60vh; */
    overflow-y: auto;
}
.modal-mask{
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 20%;
    text-align: center;
}
.modal-body{
  background-image: url(../../public/images/modal.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.modal-content{
  background: #000000c7;
}
.modal-body .btn-cross{
    position: absolute;
    top: 0px;
    right: 3%;
    left: 89%;
    font-size: 24px;
    color: #776656;
}
.btn-cross:hover{
  color:#fff
}
.btn_sm{
  width: 200px;
}
.title-head{
  margin-top: 18%;
}
</style>