<template>
    <Navbar :logUser=authentication.user_name />
    <router-view></router-view>
</template>

<script>

import { reactive } from 'vue';
import router from '../router'
import Navbar from '../components/Navbar'
export default {
  name: "mainView",
  components:{
    Navbar
  },
  data(){
        return {
             gameOver:null,
             hoursLeft:null,
             account:null,
             currentRoute:null,
             expiredDate:null,
             winningToken:0
        }
    },
  mounted(){
    // validate session on mounting page
    if(!localStorage.getItem('wax_user')) {
        router.push('/login')
    }
  },
  async created() {
    
  },
  setup() {
    const authentication = reactive({ user_name: localStorage.getItem('wax_user') });
    return {
      authentication
    };
  },
  methods: {
    logout: function() {
      router.push('/login')
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

