<template>
  <div class="raiding-wrapper">
    <div class="row align-items-lg-center">
      <div class="col-md-6 col-sm-8">
        <div class="raiding-img-prev d-flex">
          <template v-if="chars.chars[0]">
            <div class="card">
              <img :src="chars.chars[0]" :alt="chars.chars[0]" @error="imageLoadError($event,chars.chars[0],0)" />
            </div>
          </template>
          <template v-if="chars.chars[1]">
            <div class="card">
              <img :src="getImgUrl(chars.chars[1])" :alt="chars.chars[1]" @error="imageLoadError($event,chars.chars[1],1)" />
            </div>
          </template>
          <template v-if="chars.chars[2]">
            <div class="card">
              <img :src="getImgUrl(chars.chars[2])" :alt="chars.chars[2]" @error="imageLoadError($event,chars.chars[2],2)" />
            </div>
          </template>
          
          <template v-if="chars.chars[3]">
            <div class="card">
              <img :src="getImgUrl(chars.chars[3])" :alt="chars.chars[3]" @error="imageLoadError($event,chars.chars[2],3)" />
            </div>
          </template>
        </div>
      </div>
      <div class="col-md-2 col-sm-2">
        <div class="raiding-total_prize d-flex">
          <h2 class="buy-btn item-price_text text-white mr-2">Duration:{{chars.duration}} days</h2>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="btn-container">
          <button class="med-btn" @click="unstack(chars.staking_id)">
            <div class="btn-container__img">
              <img src="images/md-btn.png" alt="" />
              <div class="btn-container__text">
                <h4 class="ml-3">{{countDownEnded ? "Claim":"End"}}</h4>
              </div>
            </div>
          </button>
          <h3 class="text-white">{{timerDisplay}}</h3>
        </div>
      </div>
    </div>
  </div>
  <Runes
    v-if="ShowCardSearchModal"
    :cards="myAssets"
    v-on:modal-hide-click="ShowCardSearchModal = false"
  />
</template>
<script>
import axios from 'axios'
import Runes from "../components/Runes";
import ApiService from "../_services/Service";
import { useToast } from "vue-toastification";
import moment from 'moment';
const toast = useToast();
export default {
  name: "Stake",
  props:["chars"],
  components: {
    Runes,
  },
  data: () => {
    return {
      ShowCardSearchModal: false,
      duration: 7,
      activeMenu: false,
      raidOver: false,
      counter: 0,
      progress: 0,
      factor: 1,
      leftHours: 0,
      timerDisplay: 0,
      countDown: null,
      countDownEnded: false,
      amount: 0,
      selectedRarity: [],
      rarity: "One Week",
    };
  },
  mounted() {
    if (!this.raidOver && !this.countDown && this.chars.end_time) {
      let startDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
      let endDate = moment.utc(this.chars.end_time).format('YYYY-MM-DD HH:mm:ss');
      var seconds = moment.duration(moment(endDate).diff(moment(startDate))).asSeconds();
        this.interval = setInterval(() => {
          const days = Math.floor(seconds / 86400);
          const remainingMillies= (seconds % 86400) * 1000;
          this.timerDisplay = `${days} day(s) and ${moment.utc(remainingMillies).format('HH:mm:ss')}`;
            seconds--;
            if (seconds === 0 || days<0 || this.countDownEnded) {
              this.countDownEnded=true;
              this.timerDisplay = "Raid finished!"
              clearInterval(this.interval)
            }
        }, 1000);
    } else {
        clearInterval(this.countDown)
    }
  },
  methods: {
    async imageLoadError (e,item) {
      await axios
                .get(`${process.env.VUE_APP_API_ASSET_URL}/${item}`)
                .then((response) => {
                  e.target.src = ApiService.getImgUrl(response["data"].data.data.img,"&w=200")
                  this.selectedRarity.push(response["data"].data.data.rarity);
                  // (e.target as HTMLImageElement).src = ApiService.getImgUrl(response["data"].data.data.img)
                })
    },
    getImgUrl(img) {
      return `images/templates/${img}.png`
    },
    duratoinSelect(val) {
      this.duration = val;
      this.activeMenu = false;
      this.rarity = this.duration===7? "One Week":"One Month";
    },
    async unstack(id) {
      if (confirm("Are you sure?you want to end the raid.") == true) {
    try {
        const actionData = {
            account: "midgardtrade",
            name: 'unstaking',
            authorization: [{
                actor: localStorage.getItem("wax_user"),
                permission: 'active',
            }],
            data: {
              account:localStorage.getItem("wax_user"),
              staking_id:id
            },
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await ApiService.doSign(actionData, (res) => {
            if (res.processed) {
              // this.$store.commit("decrementBalance", 10000);
              toast.success("Transaction processing.");
              ApiService.saveActivity({
                trxId:res.processed.id,
                blockNum:res.processed.block_num,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                rarity:this.selectedRarity
              }).then((apires)=>{
                ApiService.leaderBoardEntry({
                  stake_id:id,
                  type:"raid",
                  duration:this.chars.duration,
                  account:localStorage.getItem("wax_user"),
                  status:"Done",
                  log:apires,
                  rarity:this.selectedRarity
                });
              });
            }else{
              ApiService.leaderBoardEntry({
                stake_id:id,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:res,
                rarity:this.selectedRarity
              });
            }
          },
          (error) => {
            error = ApiService.errorFormat(error);
            toast.error(error);
            ApiService.leaderBoardEntry({
                stake_id:id,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:[],
                rarity:this.selectedRarity
              });
          })
        } else {
            return await ApiService.signWithWax({ actions: [actionData] }, (res) => {
            if (res.processed) {
              // this.$store.commit("decrementBalance", 10000);
              toast.success("Transaction processing.");
              ApiService.saveActivity({
                trxId:res.processed.id,
                blockNum:res.processed.block_num,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                rarity:this.selectedRarity
              }).then((apires)=>{
                ApiService.leaderBoardEntry({
                  stake_id:id,
                  type:"raid",
                  duration:this.chars.duration,
                  account:localStorage.getItem("wax_user"),
                  status:"Done",
                  log:apires,
                  rarity:this.selectedRarity
                });
              })
            }else{
              ApiService.leaderBoardEntry({
                stake_id:id,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:res,
                rarity:this.selectedRarity
              });
            }
          },
          (error) => {
            error = ApiService.errorFormat(error);
            toast.error(error);
            ApiService.leaderBoardEntry({
                stake_id:id,
                type:"raid",
                duration:this.chars.duration,
                account:localStorage.getItem("wax_user"),
                status:"failed",
                log:[],
                rarity:this.selectedRarity
              });
          })
        }
    } catch (err) {
        return ApiService.onErrorCallback(err)
    }
     }
    },
  },
};
</script>

