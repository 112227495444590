<template>
  <transition name="modal fade">
    <div class="modal-mask modal-backdrop">
      <div class="">
        <div class="modal-wrapper">
          <div class="modal-dialogs" role="document">
            <div class="modal-content modal-img">
              <div class="modal-body p-5 overflow-hidden">
                <p v-if="error">{{ error }}</p>
                <div class="text-center">
                  <a
                    href="JavaScript:void(0)"
                    class="btn-cross text-right close-modal"
                    @click="hideModal($event)"
                    >&times;</a
                  >
                  <h1 class="tm5 title-head d-inline-block">
                    How is weapon training works
                  </h1>
                  <div class="scroll overflow-y">
                    <div class="row align-items-center mt-4">
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/bronze-weapon.webp" alt="" />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <h3 class="text-white mr-3">
                          +Bronze Rune+400 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/weapon-card.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-4">
                      <div class="col-md-3">
                        <div class="card">
                          <img src="/images/sliver-card.png" alt="" />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <h3 class="text-white mr-3">
                          +silver Rune+800 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/gold-weapon.webp" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-4">
                      <div class="col-md-3">
                        <div class="card">
                          <img src="/images/gold-card-sword.png" alt="" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3 class="text-white mr-3">
                          +Gold Rune+2000 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/plat-weapon.webp" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <h1 class="tm5 title-head d-inline-block">
                        How is character training work
                      </h1>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-3">
                        <div class="card character">
                          <div class="d-flex character-train_card">
                            <img
                              src="images/character-bronze.png"
                              alt=""
                              class="character-train__img"
                            />
                            <img
                              src="images/character-bronze.png"
                              alt=""
                              class="character-train__img"
                            />
                          </div>
                          <div class="d-flex character-train_card char-pos">
                            <img src="images/character-bronze.png" alt="" />
                            <img src="images/character-bronze.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 d-flex align-items-center">
                        <h3 class="text-white mr-3">
                          +Bronze Rune+200 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/character-bronze.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 sliver-rune">
                      <div class="col-md-3">
                        <div class="card character">
                          <div class="d-flex character-train_card">
                            <img
                              src="/images/sliver-char.png"
                              alt=""
                              class="character-train__img"
                            />
                            <img
                              src="/images/sliver-char.png"
                              alt=""
                              class="character-train__img"
                            />
                          </div>
                          <div class="d-flex character-train_card char-pos">
                            <img src="/images/sliver-char.png" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 d-flex align-items-center">
                        <h3 class="text-white mr-3">
                          +sliver Rune+400 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="/images/gold-char-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 align-items-center">
                      <div class="col-md-3">
                        <div class="card character">
                          <div class="d-flex character-train_card">
                            <img
                              src="/images/gold-char-2.png"
                              alt=""
                              class="character-train__img"
                            />
                            <img
                              src="/images/gold-char-2.png"
                              alt=""
                              class="character-train__img"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <h3 class="text-white mr-3">
                          +Gold Rune+1000 YGD
                          <span class="infotext">=</span>
                        </h3>
                      </div>
                      <div class="col-md-3">
                        <div class="card">
                          <img src="images/plat-chars.webp" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  props: ["error", "showModel"],
  showModal: true,
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-wrapper {
  overflow: hidden !important;
}
.close-modal {
  font-size: 28px;
  position: absolute;
  top: 28px;
  right: 31px;
  color: #e9c8a8;
}
.modal-dialogs {
  width: 485px;
  margin: auto;
  /* max-width: 700px; */
}

.modal-img {
  background: url("../../public/images/modal-frame-min.png") no-repeat;
  background-size: contain;
  height: 408px;
}
.coin-img img {
  width: 20px !important;
  height: 20px !important;
}
.infotext {
  margin-left: 26px;
  font-size: 27px;
  /* margin-top: 30px; */
  position: relative;
  top: 3px;
}
.character {
  position: relative;
}
.character-train_card img {
  width: 100%;
  height: 66px;
  /* padding: 3px; */
}
.character-train__img {
  padding: 3px;
}
.sliver-rune .character-train__img {
  padding: 0 !important;
}
.char-pos {
  position: absolute;
  top: 37px;
  padding: 0 !important;
  left: 6px;
}
.sliver-rune .char-pos {
  left: 27px;
}
.char-pos img {
  height: 57px;
}
</style>
