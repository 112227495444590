<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Bag</h5>
                <button type="button" class="close modal-close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" @click="hideModal($event)">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p v-if="error">{{error}}</p>
                <div class="row">
                  <div class="col img-f" v-for="(info,index) in row" :key="index">
                    <div class="card card__image pop-card">
                      <template v-if="'img' in info.data">
                        <img class="card-img-top" v-show="isImageLoaded" :src="getImgUrl(info.data.img)" alt="{{info.data.name}}" @load="loaded">
                        <img v-if="!isImageLoaded" class="loading card-img-top" :src="'/images/img-loader.gif'">
                      </template>
                      <template v-else>
                        <video width="200" height="200" controls v-if="'video' in info.data">
                          <source :src="getImgUrl(info.data.video)" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="action"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
</template>
<script>
import ApiService from '../_services/Service'
export default {
 name:"SelectedBag",
 props:["error",'row','showModel'],
 data () {
    return {
      isImageLoaded:false,
      showModal: true
    }
  },
  methods:{
    getImgUrl(hash) {
        return ApiService.getImgUrl(hash)
    },
    hideModal(e){
        this.$emit("modal-hide-click",e);
    },
    loaded() {
      this.isImageLoaded = true;
    },
  }
}
</script>

<style>
.modal-dialog{
  width: 630px;
  max-width: 700px;
}
.modal-body .badge-item-stat-image{
 max-width: 280px;
}
.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}
/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    overflow-y: auto;
}
.modal-header{
    background: #2d1a05;
    color: #fff;
}
.img-f{
    /* box-shadow: 0 16px 32px #eceff1; */
    max-width: 200px;
}
.pop-card{
  margin-left: 2px !important;
}
</style>