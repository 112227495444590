<template>
  <BackButton link="training" text="Back" />
  <div class="container" id="container">
    <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :is-full-page="fullPage"
        />
    <div
      class="
        popup-wrapper
        d-flex
        justify-content-center
        align-items-center
        mb-5
      "
    >
      <h1 class="text-bolder head-title">Runes Piece Blending</h1>
      <button class="bg-transparent position-relative border-0 ml-3 mb-2">
        <div class="popup_button-img"></div>
        <div class="question-mark">
          <img
            src="../../public/images/question-mark.png"
            alt=""
            class="question-mark_img"
          />
        </div>
      </button>
    </div>
    <section class="card_section battle-card text-center">
      <div class="btn-container d-inline">
        <button class="large-btn mb-3">
          <div class="btn-container__img">
            <div class="dropdown-area">
              <div class="position-relative">
                <img src="images/field.png" alt="" />
                <div class="dropdown-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
                <div
                  class="dropdown position-absolute d-flex"
                  @click.prevent="activeMenu = !activeMenu"
                >
                  <h3>{{ rarity }}</h3>
                  <i class="fas fa-angle-down"></i>
                </div>
                <!-- Dropdown menu -->
                <div
                  class="dropdown-menu"
                  :class="{ 'active-menu': activeMenu }"
                >
                  <ul class="nav">
                    <a @click.prevent="setRarity('389738')" href="#"
                      >SILVER<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                    <a @click.prevent="setRarity('389737')" href="#"
                      >BRONZE<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                    <a @click.prevent="setRarity('389739')" href="#"
                      >GOLD<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div class="row justify-content-center align-items-center">
        <div
          class="col-sm-12 col-lg-6 col-md-8 col-xl-3 card_section-first--card"
        >
          <div class="stone-wrapper" v-if="!isLoading">
            <div class="row">
              <div class="col-md-6">
                <div>
                  <div class="stone-box">
                    <template
                      v-if="
                        !isLoading &&
                        selectedRunesPiece &&
                        selectedRunesPiece !== 'undefined'
                      "
                    >
                      <img :src="selectedRunesPiece" alt="" class="" />
                    </template>
                    <template v-else>
                      <img src="images/stone-plus-min.png" alt="" class="" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="stone-box">
                  <template
                    v-if="
                      !isLoading &&
                      selectedRunesPiece &&
                      selectedRunesPiece !== 'undefined'
                    "
                  >
                    <img :src="selectedRunesPiece" alt="" class="" />
                  </template>
                  <template v-else>
                    <img src="images/stone-plus-min.png" alt="" class="" />
                  </template>
                </div>
              </div>
            </div>
            <div class="stone-box-w">
              <template
                v-if="
                  !isLoading &&
                  selectedRunesPiece &&
                  selectedRunesPiece !== 'undefined'
                "
              >
                <img :src="selectedRunesPiece" alt="" class="" />
              </template>
              <template v-else>
                <img src="images/stone-plus-min.png" alt="" class="" />
              </template>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="stone-box">
                  <template
                    v-if="
                      !isLoading &&
                      selectedRunesPiece &&
                      selectedRunesPiece !== 'undefined'
                    "
                  >
                    <img :src="selectedRunesPiece" alt="" class="" />
                  </template>
                  <template v-else>
                    <img src="images/stone-plus-min.png" alt="" class="" />
                  </template>
                </div>
              </div>
              <div class="col-md-6">
                <div class="stone-box">
                  <template
                    v-if="
                      !isLoading &&
                      selectedRunesPiece &&
                      selectedRunesPiece !== 'undefined'
                    "
                  >
                    <img :src="selectedRunesPiece" alt="" class="" />
                  </template>
                  <template v-else>
                    <img src="images/stone-plus-min.png" alt="" class="" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 col-md-2 col-xl-2 equalsign">=</div>
        <div class="col-sm-12 col-lg-6 col-md-2 col-xl-2">
          <div class="card">
            <img :src="cardToGet" alt="" />
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="xl-btn" @click="transaction()">
          <div class="btn-container__img">
            <img src="images/login.png" alt="" />
            <div class="btn-container__text">
              <h4 class="ml-3">Blending</h4>
            </div>
          </div>
        </button>
      </div>
    </section>
    <Runes v-if="showModal" v-on:modal-hide-click="showModal = false" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import ApiService from "../_services/Service";
import Runes from "@/components/Runes";
import { useToast } from "vue-toastification";
const toast = useToast();
import Loading from 'vue-loading-overlay';
import BackButton from "../components/layout/BackButton"
export default {
  name: "Blending",
  components: {
    Runes,
    Loading,
    BackButton
  },
  computed: {
    ...mapState([
      "runes",
      "silverRunePiece",
      "goldRunePiece",
      "bronzeRunePiece",
    ]),
  },
  data() {
    return {
      cardToGet: "images/choose-card-frame.png",
      showModal: false,
      activeMenu: false,
      isLoading: false,
      selectedRunesPiece: false,
      templateId: "",
      info: null,
      apiRes: null,
      rarity: "Rarity Selection",
    };
  },
  created() {
  },
  mounted() {
    this.loadrunes();
  },
  methods: {
    async transaction() {
        this.isLoading = true;
        await ApiService.craft(
          {
            account: localStorage.getItem("wax_user"),
            rune_piece_template_id: this.templateId,
            amount: 1
          },
          (res) => {
            if (res.processed) {
              toast.success("Transaction processing.");
              this.$emit("modal-hide-click", res);
              this.selectedRunesPiece = false;
              this.loadrunes();
              this.isLoading = false;
            }
          },
          (error) => {
            error = ApiService.errorFormat(error);
            toast.error(error);
            this.isLoading = false;
          }
        );
    },
    getImgUrl(card) {
      if (card[0] !== undefined) {
        return ApiService.getImgUrl(card[0].data.img);
      } else {
        return "images/stone-plus-min.png";
      }
    },
    setRarity(val) {
      this.activeMenu = false;
      this.templateId = val;
      if (val === "389737") {
        this.rarity = "Bronze";
        if (this.bronzeRunePiece > 4) {
          this.cardToGet = "images/bronze-rune.png";
          this.selectedRunesPiece = "images/brun.png";
        } else {
          this.cardToGet = "images/choose-card-frame.png";
          this.selectedRunesPiece = false;
          toast.info("Not enought Bronze Runes Pieces.");
        }
      }
      if (val === "389739") {
        this.rarity = "Gold";
        if (this.goldRunePiece > 4) {
          this.cardToGet = "images/gold-rune.png";
          this.selectedRunesPiece = "images/grun.png";
        } else {
          this.cardToGet = "images/choose-card-frame.png";
          toast.info("Not enought Gold Runes Pieces.");
          this.selectedRunesPiece = false;
        }
      }
      if (val === "389738") {
        this.rarity = "Silver";
        if (this.silverRunePiece > 4) {
          this.cardToGet = "images/silver-rune.png";
          this.selectedRunesPiece = "images/srun.png";
        } else {
          this.selectedRunesPiece = false;
          toast.info("Not enought Silver Runes Pieces.");
          this.cardToGet = "images/choose-card-frame.png";
        }
      }
    },
    async loadrunes(){
      this.$store.dispatch("getRunesBalance");
    }
  },
};
</script>
<style scoped>
#container {
  margin-top: 10% !important;
}
.btn_xs {
  width: 19%;
}
.equalsign {
  font-size: 100px;
  color: #fff;
}
.stone-wrapper .stone-box-w {
  width: 112px;
  height: 134px;
  margin: auto;
}
.dropdown-icon {
  width: 20px;
  color: aliceblue;
  position: absolute;
  top: 9px;
  right: 6px;
}
.dropdown-icon svg {
  width: 100%;
  color: aliceblue;
}

.stone-wrapper .stone-box img,
.stone-wrapper .stone-box-w img {
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

