<template>
  <div class="container" id="container">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <div class="row">
      <!-- Search -->
      <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
        <div class="position-relative">
          <img src="images/field.png" alt="" />
          <div
            class="dropdown position-absolute d-flex"
            @click.prevent="activeMenuSearch = !activeMenuSearch"
          >
            <h3>{{ searchTerm }}</h3>
            <i class="fas fa-angle-down"></i>
          </div>
          <!-- Dropdown menu -->
          <div
            class="dropdown-menu"
            :class="{ 'active-menu': activeMenuSearch }"
          >
            <ul class="nav">
              <a
                @click.prevent="searchAsset('All Weapons')"
                href="JavaScript:void(0)"
                >All Weapons<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('Angurvadal')"
                href="JavaScript:void(0)"
                >Angurvadal<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('Lævateinn')"
                href="JavaScript:void(0)"
                >Lævateinn<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('Mjölnir')"
                href="JavaScript:void(0)"
                >Mjölnir<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="searchAsset('Höfuo')" href="JavaScript:void(0)"
                >Höfuo<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="searchAsset('Gungnir')"
                href="JavaScript:void(0)"
                >Gungnir<img src="images/choose-icon.png" class="dp_img"
              /></a>
            </ul>
          </div>
        </div>
      </div>
      <!-- Hero Text -->
      <div class="col-lg-4 col-md-6 col-sm-12 hero-logo">
        <div class="text-center hero_title">
          <h1 class="head-title">Choose your Weapons</h1>
        </div>
      </div>
      <!-- DropDown -->
      <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
        <div class="position-relative">
          <img src="images/field.png" alt="" />
          <div
            class="dropdown position-absolute d-flex"
            @click.prevent="activeMenu = !activeMenu"
          >
            <h3>{{ rarity }}</h3>
            <i class="fas fa-angle-down"></i>
          </div>
          <!-- Dropdown menu -->
          <div class="dropdown-menu" :class="{ 'active-menu': activeMenu }">
            <ul class="nav">
              <a @click.prevent="setRarity('all')" href="JavaScript:void(0)"
                >All<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('bronze')" href="JavaScript:void(0)"
                >BRONZE<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('silver')" href="JavaScript:void(0)"
                >SILVER<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a @click.prevent="setRarity('gold')" href="JavaScript:void(0)"
                >GOLD<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="setRarity('platinum')"
                href="JavaScript:void(0)"
                >PLATINUM<img src="images/choose-icon.png" class="dp_img"
              /></a>
              <a
                @click.prevent="setRarity('ownership')"
                href="JavaScript:void(0)"
                >OWNERSHIP<img src="images/choose-icon.png" class="dp_img"
              /></a>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <section class="card_section">
      <div class="row justify-content-center">
        <AssetCard
          v-for="(item, index) in info"
          :key="index"
          :row="index"
          :rec="item"
          v-on:showDetails-click="showDetails"
          v-on:removeSelectedItem="removeSelectedItem"
        />
        <h1 v-if="info && !info.length">No record found.</h1>
      </div>
    </section>
    <div class="row justify-content-center next_btn" v-show="showReadyButton">
      <div class="col-sm-12 text-center ml-lg-5 just">
        <a
          href="JavaScript:void(0)"
          class="text-center position-relative"
          @click.prevent="nextScreen()"
        >
          <div class="fixed_btn">
            <img src="images/login.png" alt="" class="w-25 btn_lg" />
            <div class="text-center test">
              <h3 class="text-center btn-text">Next</h3>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <SelectedBag
    v-if="showModal"
    :row="row"
    :error="error"
    v-on:modal-hide-click="showModal = false"
  />
</template>

<script lang="js">
import router from '../router'
import AssetCard from '../components/AssetCard'
import SelectedBag from '../components/SelectedBag'
import ApiService from "../_services/Service";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as waxjs from "@waxio/waxjs/dist";
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_FULL_URL,
    userAccount: localStorage.getItem("wax_user"),
    pubKeys: null,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);
export default {
  name: 'attack',
  components:{
    SelectedBag,
    AssetCard,
    Loading
  },
  data () {
    return {
      currentRoute: null,
      userFilter: null,
      authenticator: null,
      userCollection: process.env.VUE_APP_COLLECTION,
      showReadyButton: false,
      info: null,
      apiRes: null,
      collections: null,
      searchTerm: "All Weapons",
      isLoading: false,
      fullPage: true,
      status: false,
      assetId:"",
      row:{},
      assetIds:[],
      showModal: false,
      showStackModal: false,
      error:false,
      rarity:"All RARITY",
      activeMenuSearch:false,
      activeMenu:false
    }
  },
  mounted () {
    if(!localStorage.getItem('wax_user')) {
        router.push('/login')
    }
    this.authenticator = localStorage.getItem("ual-session-authenticator");
    this.init("");
  },
  async created() {
    this.currentRoute = router.currentRoute.value.name;
    await ApiService.getGameProgress(localStorage.getItem('wax_user'),async(res)=>{
      if(res){
          router.push('/wait')
      }else{
          this.getBagData();
      }
    });
  },
  methods: {
    async login() {
      // if(localStorage.getItem("ual-session-authenticator")=="Wax"){
      //   try {
      //       await wax.login();
      //   } catch (e) {
      //       console.error(e);
      //   }
      // }
    },
    async autoLogin() {
      if(localStorage.getItem("ual-session-authenticator")=="Wax"){
        let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
        if (isAutoLoginAvailable) {
            this.userAccount = wax.userAccount;
        }else {
          await wax.login();
        }
      }
    },
    searchAsset(term){
      this.searchTerm = term
      this.activeMenuSearch=false
      if(term=="All Weapons"){
        this.init("");
      }else{
        const params = ApiService.makeRequestParams(this.userFilter,this.searchTerm);
        this.init(params);
        // this.resetBag()
      }
    },
    resetBag(){
      this.info = this.info.map((item)=>{
          item.selected = false
          return item
        });
    },
    showDetails(row){
      // this.info = this.info.map((item)=>{
      //   item.selected = false
      //   return item
      // });
      this.showReadyButton = true;
      this.row = row;
    },
    removeSelectedItem(row){
      this.info = this.info.map((item)=>{
        if(item.asset_id==row.asset_id){
          item.selected = false
        }
        return item
      });
      this.showReadyButton = false;
    },
    setRarity(val){
      this.rarity = val;
      this.activeMenu=false
      if(val=="all"){
        this.info = this.apiRes
      }else{
        this.info = this.apiRes.filter((item)=>{
          let coll = item.data.rarity;
            return coll==val
        });
        // this.resetBag()
      }
    },
    sortChanged(event){
      this.userFilter = event.target.value
      this.search();
    },
    onCancel() {
        console.log('User cancelled the loader.')
    },
  search(){
    const params = ApiService.makeRequestParams(this.userFilter,this.searchTerm,this.userCollection);
    this.init(params);
  },
  nextScreen() {
    localStorage.removeItem("selectedChar");
    router.push('/character')
  },
  init(params) {
    this.isLoading = true
    ApiService.genericCall(`page=1&limit=1000&collection_name=${process.env.VUE_APP_COLLECTION}&schema_name=weapons${params}`,(response => {
        let res = response['data']
        if(res['success']) {
          this.apiRes = res['data']
          this.info = res['data']
          this.status = true
        } else {
          this.info = []
          this.status = false
        }
        this.loading = false;
        this.isLoading = false
      }))
  },
  getBagData: function(){
       ApiService.getUserBag(async (row)=>{
          if(row.weapons!== undefined){
            let weapons = row.weapons.join(', ')
            let nfts = `${weapons},${row.userchar}`
            await ApiService.setCards(nfts,row.userchar)
            router.push('/ready')
          }
        })
    }
  },
}
</script>
<style scoped>
#container {
  margin-top: 10% !important;
}
.card {
  /* margin-top: 40px !important; */
  margin-bottom: 20px;
}
</style>