<template>
<BackButton link="dashboard" text="Back" />
  <div class="container mt-10 text-center" id="container">
    <div
      class="
        popup-wrapper
        d-flex
        justify-content-center
        align-items-center
        mb-4
      "
    >
      <h1 class="head-title">Raiding</h1>
      <button
        class="bg-transparent position-relative border-0 ml-2 mb-3"
        @click="showWModal = true"
      >
        <div class="popup_button-img"></div>
        <div class="question-mark">
          <img
            src="images/question-mark.png"
            alt=""
            class="question-mark_img"
          />
        </div>
      </button>
    </div>
    <template v-if="stakes">
    <Stake v-for="(item,index) in stakes" :key="index" :chars=item />
    </template>
      <div class="py-md-4 d-inline-block">
        <div class="btn-container" v-if="nextRec">
          <a href="JavaScript:void(0)" class="small-btn" @click.prevent="getMyStakes(nextRec)">
            <div class="btn-container__img">
              <img src="images/backBtn.png" alt="">
              <div class="btn-container__text">
                <h4>Loadmore</h4>
              </div>
            </div>
          </a>
        </div>
    </div>
    <RaidSlot />
    <Modal
      :error="error"
      v-if="showWModal"
      v-on:w-modal-hide-click="showWModal = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import RaidSlot from "../components/RaidSlot";
import Stake from "../components/Stake";
import Modal from "../components/Modal.vue";
import ApiService from "../_services/Service";
import BackButton from "../components/layout/BackButton"
// import { useToast } from "vue-toastification";
// const toast = useToast();
export default {
  name: "Raiding",
  components: {
    RaidSlot,
    Stake,
    BackButton,
    Modal,
  },
  data: () => {
    return { ShowCardSearchModal: false, showWModal: false,nextRec:false };
  },
  computed: mapState(["myAssets", "selectedChar","stakes"]),
  async mounted() {
    await this.getAsset();
    await this.getMyStakes();
  },
  methods: {
    getImgUrl(pet) {
      return ApiService.getImgUrl(pet);
    },
    async getAsset(){
      return await ApiService.getAssets("", (rows) => {
          if (rows) {
              this.$store.commit("setMyAssets", rows);
          }
      })
    },
    async getMyStakes(nextRec=null){
      return await ApiService.getRaid((res) => {
          if (res) {
              if(res.more){
                this.nextRec = res.next_key
              }else{
                this.nextRec = false
              }
              this.$store.commit("setMyStakes", res.rows);
          }
      },nextRec)
    },
  },
};
</script>

