<template>
  <BackButton link="training" text="Back" />
  <div class="container" id="container">
    <div
      class="
        popup-wrapper
        d-flex
        justify-content-center
        align-items-center
        mb-4
      "
    >
      <h1 class="head-title">Train Your Card</h1>
      <button
        class="bg-transparent position-relative border-0 ml-2 mb-3"
        @click="showWModal = true"
      >
        <div class="popup_button-img"></div>
        <div class="question-mark">
          <img
            src="images/question-mark.png"
            alt=""
            class="question-mark_img"
          />
        </div>
      </button>
    </div>
    <div class="btn-wrapper mb-3">
      <div class="btn-container d-inline">
        <button class="large-btn" @click="filter('characters')">
          <div class="btn-container__img">
            <img src="images/btnselect.png" alt="" />
            <div class="btn-container__text">
              <h4 :class="{ active: selectedFilter === 'characters' }">
                Characters
              </h4>
            </div>
          </div>
        </button>
      </div>
      <div class="btn-container d-inline">
        <button class="large-btn" @click="filter('weapons')">
          <div class="btn-container__img">
            <img src="images/btnselect.png" alt="" />
            <div class="btn-container__text">
              <h4
                class="ml-3"
                :class="{ active: selectedFilter === 'weapons' }"
              >
                Weapons
              </h4>
            </div>
          </div>
        </button>
      </div>
      <div class="btn-container d-inline">
        <button class="large-btn mb-3">
          <div class="btn-container__img">
            <div class="dropdown-area">
              <div class="position-relative">
                <img src="images/field.png" alt="" />
                <div
                  class="dropdown position-absolute d-flex"
                  @click.prevent="activeMenu = !activeMenu"
                >
                  <h3>{{ rarity }}</h3>
                  <i class="fas fa-angle-down"></i>
                </div>
                <!-- Dropdown menu -->
                <div
                  class="dropdown-menu"
                  :class="{ 'active-menu': activeMenu }"
                >
                  <ul class="nav">
                    <a @click.prevent="setRarity('silver')" href="#"
                      >SILVER<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                    <a @click.prevent="setRarity('bronze')" href="#"
                      >BRONZE<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                    <a @click.prevent="setRarity('gold')" href="#"
                      >GOLD<img src="images/choose-icon.png" class="dp_img"
                    /></a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
    <section class="card_section battle-card">
      <div class="row">
        <div class="col-md-8">
          <div class="train-wrapper">
            <div class="container">
              <div class="train-card-body scroll">
                <div class="row mb-4">
                  <ChooseCard
                    v-for="(item, index) in info"
                    :key="index"
                    :row="index"
                    :rec="item"
                    v-on:card-click="cardClicked"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-frame">
            <template v-if="selectedRunes.length">
              <img
                v-for="(card, i) in selectedRunes"
                :key="i"
                :row="card"
                :src="showImg(card.data.img)"
                class="card-frame__img"
                v-bind:style="{
                  width: 295 / selectedRunes.length + 'px',
                  objectFit: 'contain',
                }"
              />
            </template>
            <template v-else>
              <img
                :src="selectedImage"
                class="card-frame__img fit-300"
                alt=""
              />
            </template>
            <h4 class="text-center card-limit">
              {{ totalSelectedCards }}/{{ cardLimit }}
            </h4>
          </div>
          <div class="btn-container">
            <button class="xl-btn" @click="train()">
              <div class="btn-container__img">
                <img src="images/login.png" alt="" />
                <div class="btn-container__text">
                  <h4 class="ml-3">Train</h4>
                </div>
              </div>
            </button>
          </div>
        </div>
        <br />
      </div>
    </section>
    <TrainingConfirm
      v-if="showTrainingConfirm"
      :filter="selectedFilter"
      :rarity="rarity"
      v-on:modal-hide-click="showTrainingConfirm = false"
    />
    <TrainInfoModal
      :error="error"
      v-if="showWModal"
      v-on:w-modal-hide-click="showWModal = false"
    />
  </div>
</template>
<script>
import router from "../router";
import ApiService from "../_services/Service";
import ChooseCard from "@/components/ChooseCard";
import TrainInfoModal from "../components/TrainInfoModal";
import TrainingConfirm from "../components/TrainingConfirm";
import BackButton from "../components/layout/BackButton";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "Train",
  components: {
    // TrainingCard,
    BackButton,
    TrainingConfirm,
    ChooseCard,
    // Modal,
    TrainInfoModal,
  },
  computed: {
    ...mapGetters(["selectedRunes", "runes"]),
  },
  data() {
    return {
      selectedImage: "images/choose-card-frame.png",
      isLoading: false,
      apiRes: null,
      row: null,
      info: null,
      selectedFilter: "characters",
      error: null,
      cardLimit: 3,
      totalSelectedCards: 0,
      showWModal: false,
      isImageLoaded: false,
      activeMenu: false,
      showTrainingConfirm: false,
      rarity: "silver",
      isModalVisible: false,
    };
  },
  mounted() {
    if (!localStorage.getItem("wax_user")) {
      router.push("/login");
    }
    this.authenticator = localStorage.getItem("ual-session-authenticator");
    (async () => {
      await this.init("schema_name=characters");
      this.setRarity("silver");
      this.rarity = "silver";
    })();
  },
  methods: {
    cardClicked(selectedRow) {
      this.selectedImage = ApiService.getImgUrl(selectedRow.data.img);
      this.selectedRunes = this.selectedRunes.map((item) => {
        item.len = 100;
        return item;
      });
      this.row = selectedRow;
      this.totalSelectedCards = this.selectedRunes.length;
      this.info = this.apiRes.filter((item) => item.template.template_id===selectedRow.template.template_id);
    },
    showImg(img) {
      return ApiService.getImgUrl(img);
    },
    async init(params) {
      this.isLoading = true;
      await ApiService.genericCall(`page=1&limit=1000&collection_name=${process.env.VUE_APP_COLLECTION}&${params}`
        ,(response) => {
          let res = response["data"];
          if (res["success"]) {
            this.apiRes = res["data"];
            this.info = this.apiRes
          } else {
            this.info = [];
          }
          this.isLoading = false;
        });
    },
    setRarity(val) {
      this.rarity = val;
      this.activeMenu = false;
      if (val == "all") {
        this.info = this.apiRes;
      } else {
        this.info = this.apiRes.filter((item) => {
          let coll = item.data.rarity;
          return coll == val;
        });
        if (this.selectedFilter === "weapons") {
          this.cardLimit = 1;
        } else {
          switch (val) {
            case "silver":
              this.cardLimit = 3;
              break;
            case "gold":
              this.cardLimit = 2;
              break;
            case "bronze":
              this.cardLimit = 4;
              break;
          }
        }
      }
      this.totalSelectedCards = 0;
      this.$store.commit("clearRune");
    },
    calculateLen() {
      return 100;
    },
    filter(val) {
      this.selectedFilter = val;
      (async () => {
        await this.init(`schema_name=${val}`);
        this.setRarity("silver");
        this.rarity = "silver";
        if (val === "weapons") {
          this.cardLimit = 1;
        } else {
          this.cardLimit = 3;
        }
        this.totalSelectedCards = 0;
      })();
      this.$store.commit("clearRune");
      // alert("clicked");
      // this.showModal = true;
    },
    async train() {
      if (this.cardLimit !== this.totalSelectedCards) {
        toast.info(
          `Please select ${
            this.cardLimit - this.totalSelectedCards
          } more cards to continue`
        );
      } else {
        this.showTrainingConfirm = true;
      }
    },
  },
};
</script>
<style scoped>
#container {
  margin-top: 10% !important;
}
.card-limit {
  width: 300px;
  font-size: 20px;
  color: #c7b595;
  margin-top: 10px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

