<template>
<div class="col-sm-12 col-md-2 col-lg-6 col-xl-2">
        <div class="card bg-transparent">
            <div class="content-overlay"></div>
            <div class="card_img" :class="{selected:info.selected}">
                <template v-if="'img' in info.data">
                    <a href="JavaScript:void(0)" @click.prevent="addToBag(info,info.selected)"><img class="w-100" v-show="isImageLoaded" :src="getImgUrl(info.data.img)" alt="{{info.data.name}}" @load="loaded"></a>
                    <img v-if="!isImageLoaded" class="loading w-100" :src="'/images/loader.gif'">
                </template>
                <template v-else>
                    <p>No Image found</p>
                </template>
            </div>
            <div class="card_hover-effect battle_start-1 animation-bottom fadeIn-bottom">
                <a href="JavaScript:void(0)" class="position-relative" @click.prevent="addToBag(info,info.selected)">
                    <img src="images/btnselect.png" alt="" :class="{selectedImg:info.selected}">
                    <div class="card_text battle_start-text">
                        <h3 v-if="info.selected">unselect</h3>
                        <h3 v-if="!info.selected">select</h3>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../_services/Service'
var bag = [];
export default {
    name: 'AssetCard',
    props:["rec",'row'],
    data(){
      return {
        isImageLoaded:false,
        showReadyButton:false
      }
    },
    computed: {
        info: function(){
            return this.rec
        }
    },
    methods:{
      getImgUrl(pet) {
          return ApiService.getImgUrl(pet)
      },
      loaded() {
          this.isImageLoaded = true;
      },
      addToBag(rec,isSelected){
        if(isSelected){
          bag = bag.filter((item)=>{
            if(item.asset_id!=rec.asset_id){
              return item
            }
          });
          localStorage.setItem("selectedItem",JSON.stringify(bag));
          this.$emit("removeSelectedItem",rec);
          return;
        }
        if(bag.length < 3){
          bag.push(rec);
          this.info.selected = true
          this.$emit("showDetails-click",bag,isSelected);
          localStorage.setItem("selectedItem",JSON.stringify(bag));
        }else{
          if(localStorage.getItem("selectedItem")===null){
            bag=[];
            bag.push(rec);
            this.info.selected = true
            this.$emit("showDetails-click",bag,isSelected);
            localStorage.setItem("selectedItem",JSON.stringify(bag));
          }
        }
      },

    }
}
</script>