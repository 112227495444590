<template>
<section>
        <div class="back_btn">
            <div class="py-5">
                <a href="JavaScript:void(0)" class="position-relative" @click.prevent="goBack()">
                    <img src="images/btnselect.png" alt="">
                    <div class="position-absolute d-flex btn-text">
                        <i class="fas fa-angle-left mr-2 fa-2x text-white"></i>
                        <h3>Back</h3>
                    </div>
                </a>
            </div>
        </div>
    </section>
  <div class="container" id="container">
    <loading v-model:active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"/>
      <section class="hero-area">
            <div class="row">
                <!-- Search -->
                <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
                    <div class="position-relative">
                        <img src="images/field.png" alt="">
                        <div class="dropdown position-absolute d-flex" @click.prevent="activeMenuSearch = !activeMenuSearch">
                            <h3>{{searchTerm}}</h3>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <!-- Dropdown menu -->
                        <div class="dropdown-menu" :class="{'active-menu': activeMenuSearch}">
                            <ul class="nav">
                                <a @click.prevent="searchAsset('All Charaters')" href="JavaScript:void(0)">All Charaters<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="searchAsset('Loki')" href="JavaScript:void(0)">Loki<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="searchAsset('Thor')" href="JavaScript:void(0)">Thor<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="searchAsset('Odin')" href="JavaScript:void(0)">Odin<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="searchAsset('Heimdallr')" href="JavaScript:void(0)">Heimdallr<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="searchAsset('Frithiof')" href="JavaScript:void(0)">Frithiof<img src="images/choose-icon.png" class="dp_img"></a>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Hero Text -->
                <div class="col-lg-4 col-md-6 col-sm-12 hero-logo">
                    <div class="text-center hero_title">
                        <h1 class="primary-dark">Choose a Character</h1>
                    </div>
                </div>
                <!-- DropDown -->
                <div class="col-lg-4 col-md-6 col-sm-12 dropdown-area mt-3">
                    <div class="position-relative">
                        <img src="images/field.png" alt="">
                        <div class="dropdown position-absolute d-flex" @click.prevent="activeMenu = !activeMenu">
                            <h3>{{rarity}}</h3>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <!-- Dropdown menu -->
                        <div class="dropdown-menu" :class="{'active-menu': activeMenu}">
                            <ul class="nav">
                                <a @click.prevent="setRarity('all')" href="JavaScript:void(0)">All<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="setRarity('bronze')" href="JavaScript:void(0)">BRONZE<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="setRarity('silver')" href="JavaScript:void(0)">SILVER<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="setRarity('gold')" href="JavaScript:void(0)">GOLD<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="setRarity('platinum')" href="JavaScript:void(0)">PLATINUM<img src="images/choose-icon.png" class="dp_img"></a>
                                <a @click.prevent="setRarity('ownership')" href="JavaScript:void(0)">OWNERSHIP<img src="images/choose-icon.png" class="dp_img"></a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
      <section class="card_section">
            <div class="row justify-content-center">
              <CharacterCard v-for="(item,index) in info" :key="index" :row="index" :rec=item v-on:showDetails-click="showDetails" />
              <h1 v-if="info && !info.length">No record found.</h1>
            </div>
      </section>
      <div class="row justify-content-center next_btn" v-show="showReadyButton">
            <div class="col-sm-12 text-center ml-lg-5 just">
                <a href="JavaScript:void(0)" class="text-center position-relative" @click.prevent="ready()">
                   <div class="fixed_btn">
                    <img src="images/login.png" alt="" class=" w-25 btn_lg">
                    <div class="text-center test">
                        <h3 class="text-center btn-text">Get Ready</h3>
                    </div>
                   </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import router from '../router'
import CharacterCard from '../components/CharacterCard'
import ApiService from "../_services/Service";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as waxjs from "@waxio/waxjs/dist";
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_FULL_URL,
    userAccount: localStorage.getItem("wax_user"),
    pubKeys: null,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);
import { useToast } from 'vue-toastification'
const toast = useToast()

import { reactive } from 'vue';
export default {
  name: 'CharacterSelect',
  components:{
    CharacterCard,
    Loading
  },
  inheritAttrs: false,
  data () {
    return {
      userFilter: null,
      authenticator: null,
      userCollection: process.env.VUE_APP_COLLECTION,
      showReadyButton: false,
      info: null,
      apiRes: null,
      collections: null,
      searchTerm: "All Charaters",
      isLoading: false,
      fullPage: true,
      status: false,
      assetId:"",
      row:{},
      weapons:{},
      assetIds:[],
      showModal: false,
      showStackModal: false,
      error:false,
      activeMenuSearch:false,
      rarity:"All RARITY",
      activeMenu:false
    }
  },
  setup() {
    // check authentication details
    const authentication = reactive({ user_name: localStorage.getItem('wax_user') });
    return {
      authentication
    };
  },
  created () {
    this.weapons = JSON.parse(localStorage.getItem("selectedItem"));
  },
  mounted () {
    if(!localStorage.getItem('wax_user')) {
      router.push('/login')
    }
    if(!localStorage.getItem("selectedItem")){
       this.$router.push("attack");
    }
    this.authenticator = localStorage.getItem("ual-session-authenticator");
    this.init("");
  },
  methods: {
    async login() {
      if(localStorage.getItem("ual-session-authenticator")=="Wax"){
        try {
            await wax.login();
        } catch (e) {
            console.error(e);
        }
      }
    },
    goBack(){
      localStorage.removeItem("selectedItem");
      localStorage.removeItem("selectedChar");
      router.push('/attack')
    },
    setRarity(val){
      this.rarity = val;
      this.activeMenu=false
      if(val=="all"){
        this.info = this.apiRes
      }else{
        this.info = this.apiRes.filter((item)=>{
          let coll = item.data.rarity;
            return coll==val
        });
      }
    },
    async autoLogin() {
      if(localStorage.getItem("ual-session-authenticator")=="Wax"){
        let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
        if (isAutoLoginAvailable) {
            this.userAccount = wax.userAccount;
        }else {
          await wax.login();
        }
      }
    },
    searchAsset(term){
      this.searchTerm = term
      this.activeMenuSearch=false
      if(term=="All Charaters"){
        this.init("");
      }else{
        const params = ApiService.makeRequestParams(this.userFilter,this.searchTerm);
        this.init(params);
      }
    },
    showDetails(row,val){
      this.info = this.info.map((item)=>{
        item.selected = false
        return item
      });
      this.showReadyButton = val;
      this.row = row;
    },
    sortChanged(event){
      this.rarity = event.target.value
      this.search();
    },
    onCancel() {
        console.log('User cancelled the loader.')
    },
  search(){
    const params = ApiService.makeRequestParams(this.rarity,this.searchTerm,this.userCollection);
    this.init(params);
  },
  ready() {
    this.makeTrans();
  },
  async makeTrans(){
    let userAccount = localStorage.getItem("wax_user")
    const weapons = this.weapons.map((item)=>item.asset_id);
    if(this.authenticator!="Wax"){
      await ApiService.doSign({
        account: "midgardtrade",
        name: "getready",
        authorization: [{
          actor: userAccount,
          permission: 'active',
        }],
        data: {
          account: userAccount,
          weapons: weapons,
          userchar: this.row[0].asset_id,
        },
      },((res)=>{
        if(res.processed){
        toast.success("You are ready to attack.");
          router.push('/ready')
        }
      }),((error)=>{
        this.error = ApiService.errorFormat(error)
        toast.error(this.error);
      }));
      return;
    }
    await wax.isAutoLoginAvailable();
    if(!wax.api) {
      await this.autoLogin()
    }
    userAccount = wax.userAccount;
    try {
      let result = await wax.api.transact({
      actions: [{
        account: 'midgardtrade',
        name: 'getready',
        authorization: [{
          actor: userAccount,
          permission: 'active',
        }],
        data: {
          account: userAccount,
          weapons: weapons,
          userchar: this.row[0].asset_id,
        },
      }]
    }, {
      blocksBehind: 3,
      expireSeconds: 1200,
    });
    await new Promise(resolve => setTimeout(resolve, 1000));
        if(result.transaction_id){
          router.push('/ready')
          toast.success("You are ready to attack.");
        }
    } catch (error) {
      this.error = ApiService.errorFormat(error)
      toast.error(this.error);
    }
  },
  init(params) {
    this.isLoading = true
    ApiService.genericCall(`page=1&limit=1000&collection_name=${process.env.VUE_APP_COLLECTION}&schema_name=characters${params}`,(response => {
        let res = response['data']
        if(res['success']) {
          this.apiRes = res['data']
          this.info = res['data']
          var collections = Object.keys(this.apiRes).map(function(k){
            return res['data'][k].collection;
          });
          if(!params){
            this.collections = collections.reduce((col, current) => {
            const x = col.find(item => item.name === current.name);
              if (!x) {
                return col.concat([current]);
              } else {
                return col;
              }
            }, []);
          }
          this.status = true
        } else {
          this.info = []
          this.status = false
        }
        this.loading = false;
        this.isLoading = false
      }))
  },
  },
}
</script>
<style scoped>
.card{
  margin-bottom: 20px;
}
.card_img{
  margin-top: 15px;
}
</style>
