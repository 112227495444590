<template>
  <section class="market-section">
    <BackButton link="dashboard" text="Back" />
    <div class="container">
        <loading
            v-model:active="isLoading"
            :can-cancel="true"
            :is-full-page="fullPage"
        />
      <div class="d-flex market-item">
        <div class="card-item">
          <img :src="selectedImage" alt="" class="card-item_img" />

          <div class="input-container mx-5">
            <img
              src="../../public/images/minus-img.png"
              @click="addQuantity(false)"
              alt=""
            />

            <input
              type="text"
              v-model="quantity"
              id="inputVal"
              class="card-item_input text-center"
            />

            <img
              src="../../public/images/plus-img.png"
              @click="addQuantity(1)"
              alt=""
            />
          </div>
          <div class="btn-container">
            <button class="xl-btn" @click="buyCard()">
              <div class="btn-container__img">
                <img
                  src="../../public/images/login.png"
                  alt=""
                  class="xl-btn_img"
                />
                <div class="btn-container__text">
                  <h4>Buy Card</h4>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="scroll scroller card-list" id="test">
          <MarketCard
                v-for="(item, index) in info"
                :key="index"
                :row="index"
                :rec="item"
                :priceFeed="priceFeed"
                v-on:card-clicked="cardClicked"
            />
          </div>
        </div>
      </div>
  </section>
</template>
<script>
import ApiService from "../_services/Service";
import MarketCard from "../components/MarketCard"
import BackButton from "../components/layout/BackButton"
import Loading from 'vue-loading-overlay';
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    name: "Market",
  components:{
      MarketCard,
      BackButton,
      Loading
  },
  data() {
    return {
      selectedImage: "images/card-1.png",
      quantity:0,
      isLoading: false,
      fullPage: true,
      selectedRow: null,
      price: 0,
      priceFeed: 0,
      info:null
    };
  },
  async created() {
    return await ApiService.priceFeed((res)=>{
      this.priceFeed = res.token_price;
      this.$store.commit("setTokenPrice", this.priceFeed);
      this.init();
    });
  },
  async mounted() {
      
  },
  methods: {
    getImgUrl(pet) {
        return ApiService.getImgUrl(pet)
    },
    cardClicked(rec,price) {
      this.selectedRow = rec;
      this.price = price;
      this.selectedImage = ApiService.getImgUrl(rec.asset.immutable_data.img)
    },
    addQuantity(type){
        if (this.selectedRow) {
          if(type){
                if(!(this.quantity>this.selectedRow.user_quota) && this.selectedRow.user_quota!=0 && this.quantity!=this.selectedRow.user_quota){
                  this.quantity+=1;
                }
            }else{
                if(this.quantity>0)
                this.quantity-=1;
            }
        }
    },
    init(){
        this.isLoading = true
        ApiService.market((res)=>{
            this.info = res
            this.isLoading = false
        });
    },
    async buyCard(){
      if(this.quantity && this.price){
          await ApiService.buy({
            account:localStorage.getItem("wax_user"),
            asset_t:this.selectedRow.asset_t,
            quantity:this.quantity,
          },((res)=>{
            if(res.processed){
                let token = Number(this.price).toFixed(8);
                this.$store.commit('decrementBalance', token)
                toast.success(`Item(s) (${this.quantity}) purchased successfully`);
                this.init()
            }
          }),((error)=>{
            this.error = ApiService.errorFormat(error)
            toast.error(this.error);
          }));
        }else{
            this.error = "Please specify quantity";
            toast.error(this.error);
        }
    }
  },
};
</script>

<style scoped>
.card-list{
  height: 470px !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->


